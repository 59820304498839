import React from "react";
import ApiResponseViewer from "../ApiResponseViewer";
const res = `{
    "agentId": 3,
    "txnId": "123456902",
    "agentMob": 9958564947,
    "clientAgentId": 1003,
    "agentName": "Saurabh Hasija",
    "dateOfBirth": "1988-09-02T00:00:00",
    "email": "phpmysaurabh@gmail.com",
    "partnerId": 1605,
    "gender": "M",
    "aadhaarToken": "5324bnvhm38",
    "fatherName": "Hasija Singh",
    "spouseName": "",
    "category": "",
    "handicapped": 0,
    "shopName": "Saurabh Hasija & Company",
    "shopType": "",
    "altMobNo1": "",
    "altMobNo2": "",
    "pancard": "ADSPH5716M",
    "votersId": "",
    "passport": "",
    "drivingLicense": "",
    "address": "Roha",
    "city": "Mangaldoi",
    "district": "DARRANG",
    "state": "ASSAM",
    "pinCode": "784529",
    "altOccupationType": "Private",
    "bankRefNumber": "",
    "highestQualification": "BE",
    "isCorporate": 1,
    "activityFrom": "2019-05-10T00:00:00",
    "allocationIFSC": "IDIB000R057",
    "agentType": 1,
    "minCashHandlingLimit": 50000.00,
    "course": "No course",
    "passingDate": "2010-05-10T00:00:00",
    "expFromDate": "2010-05-10T00:00:00",
    "expToDate": "2010-05-10T00:00:00",
    "deviceName": "Computer core 2",
    "deviceCode": "12",
    "givenDate": "2010-05-10T00:00:00",
    "connectivityType": "Mobile",
    "connectivityProvider": "1",
    "providerPhoneNum": "7776940811",
    "primarySSA": "mumbai",
    "primaryVillegeCode": "jhkg",
    "primaryPinCode": "154325",
    "primarySunday": 1,
    "primaryMonday": 1,
    "primaryTuesday": 1,
    "primaryWednesday": 1,
    "primaryThursday": 1,
    "primaryFriday": 1,
    "primarySaturday": 1,
    "secondaryVillegeCode": "jhjdfghj",
    "secondaryVillegeDetails": "kdfjhgkdf",
    "secondarySunday": 1,
    "secondaryMonday": 1,
    "secondaryTuesday": 1,
    "secondaryWednesday": 1,
    "secondaryThursday": 1,
    "secondaryFriday": 1,
    "secondarySaturday": 1,
    "remunMonth": 1,
    "remunYear": 2022,
    "nreGaCard": "",
    "altOccupationDetails": "",
    "corporatedId": 46,
    "productGroup": "1",
    "accountNumber": "",
    "numberOfComplaints": "",
    "instituteName": "",
    "reasonForLeaving": "",
    "primaryStartTime": "10:20 AM",
    "primaryEndTime": "08:10 PM",
    "secondaryPinCode": "",
    "secondaryStartTime": "",
    "secondaryEndTime": "",
    "channel": 1,
    "nbfcStatus": 1,
    "createdBy": 2,
    "modifyBy": 2,
    "createdOn": "2022-01-24T23:55:49",
    "modifyOn": "2022-01-25T00:26:58"
}`.trim();
const AgentEntity = () => {
  return (
    <div style={{ display: "flex", margin: "6px" }}>
      <div style={{ flex: "1" }}>
        <h3 style={{ margin: "16px" }}>Agent Entity</h3>
        <div></div>
      </div>
      <div style={{ flex: "1" }}>
        <ApiResponseViewer response={res} />
      </div>
    </div>
  );
};

const Row = ({ first, second }) => {
  return (
    <div
      style={{
        display: "flex",
        border: "1px solid #DDDDDD",
        borderRadius: "4px",
        padding: "6px",
      }}
    >
      <div style={{ flex: "1" }}>{first}</div>
      <div style={{ flex: "2" }}>{second}</div>
    </div>
  );
};

export default AgentEntity;
