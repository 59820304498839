import React from "react";
import { Link, useHistory } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
const DocumentationNavbar = () => {
  const history = useHistory();
  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  function toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }
  function logOut() {
    history.push("/user-pages/login-2");
  }
  return (
    <nav
      style={{ background: "#FFFFFF", boxShadow: "none" }}
      className="navbar p-0 fixed-top d-flex flex-row"
    >
      <ToastContainer autoClose={3000} hideProgressBar draggableDirection="y" />
      <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo-mini" to="/">
          <span style={{ fontSize: "2rem", color: "#FFF", fontWeight: "600" }}>
            B
          </span>
        </Link>
      </div>

      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        <button
          className="navbar-toggler align-self-center"
          type="button"
          onClick={() => {
            //document.body.classList.toggle("sidebar-icon-only")
          }}
        >
          <span style={{ fontSize: "1.5rem", color: "#000" }}> Bumppy</span>
          <span
            style={{
              background: "#0073cf",
              padding: "4px",
              margin: "4px",
              borderRadius: "4px",
              color: "#FFF",
            }}
          >
            {" "}
            Docs
          </span>
        </button>

        <ul className="navbar-nav navbar-nav-right">
          <li>
            <Link>
              {" "}
              Go to Dashboard <i className="mdi mdi-arrow-right"></i>
            </Link>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <span className="mdi mdi-format-line-spacing"></span>
        </button>
      </div>
    </nav>
  );
};

export default DocumentationNavbar;
