import React, { Component, useState } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import bsCustomFileInput from "bs-custom-file-input";
const DaterangePicker = () => {
  const [startDate, setStartDate] = useState(new Date("2014/02/08"));

  const [endDate, setEndDate] = useState(new Date("2014/02/10"));

  return (
    <div className="daterange-picker col">
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        className="form-control"
      />
      <Form.Group>
        <span style={{ padding: "10px" }}> {" To "} </span>
      </Form.Group>
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        endDate={endDate}
        minDate={startDate}
        className="form-control"
      />
    </div>
  );
};

export class BasicElements extends Component {
  state = {
    startDate: new Date(),
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  componentDidMount() {
    bsCustomFileInput.init();
  }

  render() {
    return (
      <div>
        <div style={{ padding: "10px", margin: "0px",display:'flex' }} className="row">
          <div style={{ margin: "0px" }} className="col stretch-card">
            <Form.Group>
              <label>Payment Id </label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
              />
            </Form.Group>
          </div>

          <div style={{ margin: "0px",flex:'3' }} className="col stretch-card">
            <Form.Group>
              <label>Duration </label>

              <div className="row" style={{ padding: "0px" }}>
                <select className="select-drop">
                  <option>Past 7 Days</option>
                  <option>Past 30 Days</option>
                  <option>Past 90 Days</option>
                  <option>Custom Range</option>
                </select>
                <DaterangePicker />
              </div>
            </Form.Group>
          </div>

          <div style={{ margin: "0px" }} className="col stretch-card">
            <Form.Group>
              <label>Status</label>
              <select className="form-control">
                <option>All</option>
                <option>Authorized</option>
                <option>Captured</option>
                <option>Refunded</option>
                <option>Failed</option>
              </select>
            </Form.Group>
          </div>

          <div style={{ margin: "0px" }} className="col stretch-card">
            <Form.Group>
              <label>Email address</label>
              <Form.Control
                type="text"
                className="form-control"
                placeholder="Email"
                aria-label="Notes"
                aria-describedby="basic-addon2"
              />
              <small id="emailHelp" className="form-text text-muted"></small>
            </Form.Group>
          </div>
        </div>

        <div style={{ margin: "0px" ,paddingTop:'0px',paddingBottom:'0px'}} className="row">
          <div style={{ margin: "0px" }} className="row">
            <Form.Group>
              <label>Notes</label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
              />
            </Form.Group>
          </div>

          <form className="form-inline">
            <div style={{ margin: "0px" }} className="col">
              <Form.Group>
                <div className="input-group">
                  <button className="btn btn-sm btn-primary" type="button">
                    Search
                  </button>
                  <button type="button" className="btn btn-link btn-fw">
                    Clear
                  </button>
                </div>
              </Form.Group>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default BasicElements;
