import React, { useState, useEffect } from "react";
import { Tabs, Tab, Spinner } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import { urls } from "../URLs";
import BeneficiaryTable from "./BeneficiaryTable";

const { SearchBar } = Search;

const whiteStyle = { background: "#FFF" };
const greyStyle = { background: "#e7eaf6" };

const Beneficiary = () => {
  const { getBeneficiary: getBeneficiaryUrl } = urls;

  const [recordLoading, setRecordLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [reports, setReports] = useState([]);

  // const getBeneficiary = async () => {
  //   var myHeaders = new Headers();
  //   const accessToken = sessionStorage.getItem("accessToken");
  //   myHeaders.append("Authorization", `Bearer ${accessToken}`);
  //   myHeaders.append("Content-Type", "application/json");
  //   myHeaders.append(
  //     "Cookie",
  //     ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-ixWTq2CMqneGqLqb-BbP9Ujnfo1G38wJ_i-9LVAbvcAN4min4BcXzKCGmTGAa648RwBaLjSiVYGzQBfPMDszUXpHCecxCWfqG1j1zKy6jvUSYSr6A7FXTmlyrCd8txoSNXDJPngd0vPqIFJ2iGtxv3_EiQLO3C9mABt0vchwZSkoXRSVZATUhNt-VDDkh9bZkQcVp1p3kT6g1WCRanTjNQ4IRBLeNlBcBF_3xJafe36ka45O019gYptNpJ6OuYvA9UWnezm--9024h4O9jg1nTd_GbmrQ5_hvRoLjw1lVInkM9x4ERQ4dl1nUqk-ctME831pj7wlPL-3zHszBGfJJ2vT0oMgvTy8vSAjpNsRMp76eL1aTvzaNCD2n7OOGvVX3uHIEdSF1N-1hFqgBhAUp80diHtIlgAkQccV4cHpmofI2tknA-xRa2WdexcuqWSxtbdV0TTsMzPjbnUmFrya8abhTtl08ADcvGGwEaQYDWpgACynKyjEPNJPo5AM527eLMEg_Q8lCcR4-Lw6DJzdpk3A6kui1ykczTXB84gvFZsrs-tEb9jI3xIyHb05eBTS1pJM_CmYDV3vO414IdCkDSQt1JFp8l5f2B5Yk8urVOzDMetJa2rq_v5QXcP6NeID5EwqpCNanBIHoDpcW_pnbxg"
  //   );

  //   var raw = JSON.stringify({
  //     pageIndex: 1,
  //     pageSize: 10000,
  //     userId: 0,
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   const res = await fetch(getBeneficiaryUrl, requestOptions);
  //   if (res.ok) {
  //     const json = await res.text();
  //     const body = JSON.parse(json);
  //     var cur = [];
  //     body.data.map((e) => {
  //       const record = {
  //         id: "XN-18",
  //         AccountDescription: "K7891",
  //         basePrice: "₹ 0.00",
  //         status: "Closed",
  //         address1: e.address1,
  //         agentId: e.agentId,
  //         agentName: e.agentName,
  //         benefAccNo: e.benefAccNo,
  //         benefIfscCode: e.benefIfscCode,
  //         benefMob: e.benefMob,
  //         beneficiaryId: e.beneficiaryId,
  //         channel: e.channel,
  //         city: e.city,
  //         country: e.country,
  //         createdBy: e.createdBy,
  //         createdOn: e.createdOn,
  //         customerMob: e.customerMob,
  //         customerName: e.customerName,
  //         lname: e.lname,
  //         mname: e.mname,
  //         modifyBy: e.modifyBy,
  //         modifyOn: e.modifyOn,
  //         name: e.name,
  //         partnerId: e.partnerId,
  //         pincode: e.pincode,
  //         recordCount: e.recordCount,
  //         title: e.title,
  //       };
  //       cur.push(record);
  //     });
  //     setReports(cur);
  //     setRecordLoading(false);
  //   } else {
  //   }
  // };

  useEffect(() => {
    // getBeneficiary();
  }, []);
  return (
    <div>
      <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo-mini" to="/">
          <img src={require("../../assets/images/logo-mini.svg")} alt="logo" />
        </Link>
      </div>
      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        <Sidebar details={details} />
      </div>

      <div style={{ borderRadius: "22px" }} className="card m-4 p-0">
        <h2
          style={{
            margin: "0",
            background: "#3676D8",
            padding: "12px",
            borderTopLeftRadius: "22px",
            color: "#FFFFFF",
            borderTopRightRadius: "22px",
            fontWeight: "bolder",
          }}
        >
          Beneficiary
        </h2>
        <div className="card-body">
          <Tabs defaultActiveKey="Beneficiary" id="uncontrolled-tab-example">
            <Tab
              eventKey="Beneficiary"
              title="Beneficiary"
              className="test-tab"
            >
              <div>
                <BeneficiaryTable
                  setDetails={(details) => {
                    setDetails({ ...details, sidebarType: "agents" });
                    document
                      .querySelector(".content-wrapper")
                      .classList.toggle("sidebar-icon-only");
                  }}
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

const EmptyArrayHandler = () => {
  return (
    <div className="row">
      <div className="col-12">
        <span style={{ fontSize: "1.4rem" }}>No records Found</span>
      </div>
    </div>
  );
};

export default Beneficiary;
