import React, { Component } from 'react';
import { Trans } from 'react-i18next';

class Footer extends Component {
  render () {
    return (
      <footer  style={{background:"white"}} className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block"><Trans>Copyright</Trans> © 2016 <a href="https://www.bumppy.com/" target="_blank" rel="noopener noreferrer">Bumppy</a>. <Trans>All rights reserved</Trans>.</span>
          <span className="text-muted float-none float-sm-right d-block mt-1 mt-sm-0 text-center"><Trans>Hand-crafted</Trans> & <Trans>made with</Trans> <i className="mdi mdi-security text-warning"></i></span>
        </div>
      </footer>
    );
  }
}

export default Footer;