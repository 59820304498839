import React from "react";
import CMSReportTable from "./CMSReportTable";

const CMSreports = () => {
  return (
    <div style={{background:'#EDF7FF'}} className="col-md-12">
      <div style={{borderRadius:'22px'}} className="card m-4 p-0">
        <h2 style={{ margin:'0',background: "#3676D8", padding: "12px",borderTopLeftRadius:'22px',color:'#FFFFFF',borderTopRightRadius:'22px' ,fontWeight:'bolder'}}>
          CMS Report
        </h2>
        <div className="card-body">
          {/* <Tabs defaultActiveKey="Payments" id="uncontrolled-tab-example">
              <Tab eventKey="Payments" title="Payments" className="test-tab"> */}
          <CMSReportTable
            setDetails={(details) => {
              // setDetails({ ...details, sidebarType: "payments" });
              // document
              //   .querySelector(".content-wrapper")
              //   .classList.toggle("sidebar-icon-only");
            }}
          />
          {/* </Tab> */}
          {/* 
              <Tab eventKey="Refunds" title="Refunds">
                <BasicElements />
                <DataTables />
              </Tab>

              <Tab eventKey="Batch Refunds" title="Batch Refunds">
                <BasicElements />
                <DataTables />
              </Tab>

              <Tab eventKey="Orders" title="Orders">
                <BasicElements />
                <DataTables />
              </Tab>

              <Tab eventKey="Disputes" title="Disputes">
                <BasicElements />
                <DataTables />
              </Tab> */}
          {/* </Tabs> */}
        </div>
      </div>
    </div>
  );
};

export default CMSreports;