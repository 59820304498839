import React, { useContext, useState, useEffect, useRef } from "react";
import { Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

const userTypes = [
  { label: "CNF", value: "3" },
  { label: "Distributor", value: "2" },
  { label: "Retailer", value: "5" },
  { label: "API Partner", value: "9" },
];

const paymentModes = [
  { label: "Cash Deposite", value: "3" },
  { label: "UPI/IMPS", value: "2" },
  { label: "NEFT/RTGS", value: "5" },
  { label: "CDM", value: "9" },
  { label: "Fund Transfer", value: "8" },
  { label: "CHEQUE", value: "null" },
];

const CreateAgent = () => {
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [paymentMode, setPaymentMode] = useState("Select Mode");
  const [remark, setRemark] = useState("");
  const [bank, setBank] = useState("Select Bank");
  const [virtualAccount, setVirtualAccount] = useState(null);
  const [List, setList] = useState([]);
  const [File, setFile] = useState("");
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
    useState(true);
  const [accountCreated, setAccountCreated] = useState(
    localStorage.getItem("va-created") || false
  );
  const [TopupData, setTopupData] = useState({
    msg: "Remark: Wallet Top up Approval takes upto 30 mins. Balance is updated daily(9 AM to 8 PM).",
    success: false,
  });
  return (
    <div style={{padding:'2rem'}}>
      <div
        style={{
          padding: "12px",
          background: "#282323",
          color: "#FFF",
          fontWeight: "bolder",
        }}
      >
        Create Agent
      </div>
      <div>
        <div className="row p-3">
          <span>Personal Info</span>
        </div>
        <div className="row">
          <div className="col">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`Select User Type`}
              </Form.Label>
              <Select
                options={userTypes}
                onChange={(e) => {
                  setPaymentMode(e);
                }}
              />
            </Form.Group>
          </div>
          <div className="col">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`Enter Full Name`}
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter Full Name"
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>

          <div className="col">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`Enter Mobile`}
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter Mobile"
                onChange={(e) => {
                  setTransactionId(e.target.value);
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>

          <div className="col">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`Enter Email ID`}
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter Email ID"
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>

          <div className="col">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`Enter Shop Name"`}
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter Shop Name"
                onChange={(e) => {
                  setTransactionId(e.target.value);
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
        </div>
      </div>

      <div>
        <div className="row p-3">
          <span>Attach Tariff Info</span>
        </div>
        <div className="row">
          <div className="col">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`DMT Tariff`}
              </Form.Label>
              <Select
                options={userTypes}
                onChange={(e) => {
                  setPaymentMode(e);
                }}
              />
            </Form.Group>
          </div>
          <div className="col">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`AEPS Tariff`}
              </Form.Label>
              <Select
                options={userTypes}
                onChange={(e) => {
                  setPaymentMode(e);
                }}
              />
            </Form.Group>
          </div>
          <div className="col">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`Other Tariff`}
              </Form.Label>
              <Select
                options={userTypes}
                onChange={(e) => {
                  setPaymentMode(e);
                }}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`Select Sales Team`}
              </Form.Label>
              <Select
                options={userTypes}
                onChange={(e) => {
                  setPaymentMode(e);
                }}
              />
            </Form.Group>
          </div>
        </div>
      </div>

      <div>
        <div className="row p-3">
          <span>{"Address & Document Info"}</span>
        </div>
        <div className="row">
          <div className="col">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`Pin Code`}
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter Pin Code"
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>

          <div className="col">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`Shop Address`}
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter Shop Address"
                onChange={(e) => {
                  setTransactionId(e.target.value);
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>

          <div className="col">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`Aadhar Number`}
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter Aadhar Number"
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>

          <div className="col">
            <Form.Group>
              <Form.Label>
                <span style={{ color: "red" }}>*</span>
                {`Pancard Number`}
              </Form.Label>
              <Form.Control
                type="text"
                className="form-control"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Enter Pancard Number"
                onChange={(e) => {
                  setTransactionId(e.target.value);
                }}
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="">
        <div className="row">
          <div className="col">
            <Button className="rounded-0 btn-block" variant="success">
              {" "}
              Submit
            </Button>
          </div>
          <div className="col">
            <Button className="rounded-0 btn-block" variant="danger">
              {" "}
              Clear
            </Button>
          </div>
          <div className="col"></div>
          <div className="col"></div>
        </div>
      </div>
    </div>
  );
};

export default CreateAgent;
