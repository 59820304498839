import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import { urls } from "../URLs";
import CustomerSideBar from "./CustomerSideBar";
const { SearchBar } = Search;

const whiteStyle = { background: "#FFF" };
const greyStyle = { background: "#e7eaf6" };
const rowStyle = (row, index) => {
  if (index % 2 == 0) {
    return greyStyle;
  } else {
    return whiteStyle;
  }
};

const columns = [
  {
    dataField: "customerId",
    text: "Customer Id",
    sort: true,
    formatter: (row, col) => {
      return (
        <div>
          <button
            className="btn btn-link btn-fw "
            type="button"
            keyField="id"
            onClick={() =>
              document
                .querySelector(".content-wrapper")
                .classList.toggle("sidebar-icon-only")
            }
          >
            {row}
          </button>
        </div>
      );
    },
  },
  {
    dataField: "name",
    text: "Customer Name",
    sort: true,
  },
  {
    dataField: "email",
    text: "Email",
    sort: true,
  },
  {
    dataField: "city",
    text: "City",
    sort: true,
  },
  {
    dataField: "customerMob",
    text: "Customer Mobile",
    sort: true,
  },
];

const defaultSorted = [
  {
    dataField: "customerId",
    order: "desc",
  },
];

const Customers = () => {
  const { getCustomers: getCustomersUrl } = urls;
  const [customers, setCustomers] = useState([]);
  const [details, setDetails] = useState({});

  const setsidebarwidth = () => {
    document
      .querySelector(".content-wrapper")
      .classList.toggle("sidebar-icon-only");
  };

  const getCustomers = async () => {
    var myHeaders = new Headers();
    const accessToken = sessionStorage.getItem("accessToken");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-ixWTq2CMqneGqLqb-BbP9Ujnfo1G38wJ_i-9LVAbvcAN4min4BcXzKCGmTGAa648RwBaLjSiVYGzQBfPMDszUXpHCecxCWfqG1j1zKy6jvUSYSr6A7FXTmlyrCd8txoSNXDJPngd0vPqIFJ2iGtxv3_EiQLO3C9mABt0vchwZSkoXRSVZATUhNt-VDDkh9bZkQcVp1p3kT6g1WCRanTjNQ4IRBLeNlBcBF_3xJafe36ka45O019gYptNpJ6OuYvA9UWnezm--9024h4O9jg1nTd_GbmrQ5_hvRoLjw1lVInkM9x4ERQ4dl1nUqk-ctME831pj7wlPL-3zHszBGfJJ2vT0oMgvTy8vSAjpNsRMp76eL1aTvzaNCD2n7OOGvVX3uHIEdSF1N-1hFqgBhAUp80diHtIlgAkQccV4cHpmofI2tknA-xRa2WdexcuqWSxtbdV0TTsMzPjbnUmFrya8abhTtl08ADcvGGwEaQYDWpgACynKyjEPNJPo5AM527eLMEg_Q8lCcR4-Lw6DJzdpk3A6kui1ykczTXB84gvFZsrs-tEb9jI3xIyHb05eBTS1pJM_CmYDV3vO414IdCkDSQt1JFp8l5f2B5Yk8urVOzDMetJa2rq_v5QXcP6NeID5EwqpCNanBIHoDpcW_pnbxg"
    );

    var raw = JSON.stringify({
      pageIndex: 1,
      pageSize: 100000,
      userId: 0,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(getCustomersUrl, requestOptions);
      if (res.ok) {
        const json = await res.text();
        const body = JSON.parse(json);
        setCustomers(body.data);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    getCustomers();
  }, []);
  console.log(customers);

  return (
    <div>
      <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo-mini" to="/">
          <img src={require("../../assets/images/logo-mini.svg")} alt="logo" />
        </Link>
      </div>
      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        <CustomerSideBar details={details} />
      </div>
      <div style={{ borderRadius: "22px" }} className="card m-4 p-0">
        <h2
          style={{
            margin: "0",
            background: "#3676D8",
            padding: "12px",
            borderTopLeftRadius: "22px",
            color: "#FFFFFF",
            borderTopRightRadius: "22px",
            fontWeight: "bolder",
          }}
        >
          View All Services
        </h2>
        <div className="card-body">
          <Tabs defaultActiveKey="Customers" id="uncontrolled-tab-example">
            <Tab eventKey="Customers" title="Customers" className="test-tab">
              <div>
                <div className="row">
                  <div className="col-12">
                    <div style={{ padding: "0px" }} className="card">
                      <div className="row">
                        <div className="col-12">
                          {customers.length == 0 ? (
                            <EmptyArrayHandler />
                          ) : (
                            <ToolkitProvider
                              keyField="id"
                              bootstrap4
                              data={customers}
                              columns={columns}
                              search
                            >
                              {(props) => (
                                <div>
                                  <BootstrapTable
                                    rowStyle={rowStyle}
                                    rowEvents={{
                                      onClick: (e, row, rowIndex) => {
                                        console.log(row);
                                        setDetails(row);
                                      },
                                    }}
                                    defaultSorted={defaultSorted}
                                    pagination={paginationFactory()}
                                    {...props.baseProps}
                                    wrapperClasses="table-responsive"
                                  />
                                </div>
                              )}
                            </ToolkitProvider>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
const EmptyArrayHandler = () => {
  return (
    <div className="row">
      <div className="col-12">
        <span style={{ fontSize: "1.4rem" }}>No records Found</span>
      </div>
    </div>
  );
};
export default Customers;
