import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Trans } from "react-i18next";
import userAvtar from "../../assets/images/user.png";
import { Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { urls } from "../URLs";
const Navbar = () => {
  const { GetUserProfileInfo: GetUserProfileInfoUrl } = urls;
  const history = useHistory();
  const { myAccountInfo, setMyAccountInfo } = useContext(GlobalContext);
  // get my account details
  const getAccountInfo = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const userId = sessionStorage.getItem("userId");
    console.log("called user id", userId);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iza8XwzTtDsnycoa_JEX2ahOG_6MqIJje0CyELar_eYNiyc8DVdR7WY3yvlwQ9s5qK-G9UoNP6RdZTPbLL0E4Yy__ZCqoFSpUJsPMTzYhGoRbCBLEH3HiICuxNtXPw9IpsW5XRdNUA5lHOG3gBLa-TYZZUXKvhWs78Q25He_Q_MZj04E463t-zUOMNPH_2V9-HWesyARkYFQM9VWEp9jX0s96Zs-yKSE6RKVeiQVbD3QZbMk0tL6DKEREfo0FCVYaOMIGMvKX0GFGsPCXD7L63RcOqMuUCAUU2g1MenEVAWVqlP98WGqkSBtzkDMllZH809ERbz8nKyN9PlkAsXpj2KtJ2OnqWQ2_qr9UH-eFMBMtmRZ4Rcz3qP_Njkx4uwF9MOPeX9EGCoEqOcxTjp9jdC2GWIBON2t0K_Rz7tGFMXe8qGFpeOIZ9DKIZhcVeXiBOb70OBWIcsXQdPr-3rRRTePDDSieVuXUSWR2GnjosxJJqEvCga-MbD59KKJEOJ8cYr2E6dImBGcQuix6g8YCT_eijrXPKNEhZQttEzxYoe-0s0OypnpXEeuOnx9uKta06fT31SqLNomWInObIItLRF4mqH5ZNmnnKxHCatj8r4o7EUKlPTq9fH7D3F7w_Z6KENoOfTC2kz1q5VE7GHM78z"
    );

    var raw = JSON.stringify({
      userId: userId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(GetUserProfileInfoUrl, requestOptions);
      const body = await res.text();
      const i = JSON.parse(body);
      console.log("test called");
      setMyAccountInfo(i.data);
    } catch (error) {}
  };
  useEffect(() => {
    getAccountInfo();
  }, []);
  function toggleOffcanvas() {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  }
  function toggleRightSidebar() {
    document.querySelector(".right-sidebar").classList.toggle("open");
  }
  function logOut() {
    history.push("/user-pages/login-2");
  }
  console.log(myAccountInfo);
  return (
    <GlobalContext.Consumer>
      {(state) => (
        <nav
          style={{ background: "#2F3245" }}
          className="navbar p-0 fixed-top d-flex flex-row"
        >
          <ToastContainer
            autoClose={3000}
            hideProgressBar
            draggableDirection="y"
          />
          <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
            <Link className="navbar-brand brand-logo-mini" to="/">
              <span
                style={{ fontSize: "2rem", color: "#FFF", fontWeight: "600" }}
              >
                B
              </span>
            </Link>
          </div>

          <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
            <button
              className="navbar-toggler align-self-center"
              type="button"
              onClick={() =>
                document.body.classList.toggle("sidebar-icon-only")
              }
            >
              <span className="mdi mdi-menu"></span>
              <span style={{ fontSize: "1.5rem", color: "#FFF" }}> Bumppy</span>
            </button>

            <ul className="navbar-nav navbar-nav-right">
              {state.myAccountInfo == null ? null : (
                <li style={{ display: "flex" }}>
                  <Button className="mx-2" variant="info">
                    {`AEPS Bal : ₹ ${state.myAccountInfo.aepsBalance}`}
                  </Button>
                  <Button className="mx-2" variant="success">
                    {`DMT Bal : ₹ ${state.myAccountInfo.dmtBalance}`}
                  </Button>
                </li>
              )}
              <Dropdown alignRight as="li" className="nav-item">
                <Dropdown.Toggle
                  as="a"
                  className="nav-link cursor-pointer no-caret"
                >
                  <div className="navbar-profile">
                    <img
                      className="img-xs rounded-circle"
                      src={require("../../assets/images/faces/face7.jpg")}
                      alt="profile"
                    />
                    <p className="mb-0 d-none d-sm-block navbar-profile-name">
                      <Trans>
                        {state.myAccountInfo == null ? (
                          ""
                        ) : (
                          <span style={{ fontWeight: "600" }}>
                            {state.myAccountInfo.name}
                          </span>
                        )}
                      </Trans>
                    </p>
                    <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{ minWidth: "20rem" }}
                  className="navbar-dropdown preview-list navbar-profile-dropdown-menu"
                >
                  <div style={{ margin: "10px", display: "flex" }}>
                    <img
                      style={{ maxHeight: "50px", maxWidth: "50px" }}
                      src={userAvtar}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        marginLeft: "10px",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "1rem",
                          padding: "10px",
                          paddingLeft: "0px",
                        }}
                      >
                        {state.myAccountInfo == null
                          ? ""
                          : state.myAccountInfo.name}
                      </span>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span>
                          {state.myAccountInfo == null
                            ? ""
                            : state.myAccountInfo.vAccountNumber}
                        </span>
                        <button
                          onClick={() => toast.success("merchant id copied")}
                          style={{
                            fontSize: "0.8rem",
                            outline: "0",
                            border: "1px solid #b2b2b3",
                            borderRadius: "4px",
                            paddingTop: "2px",
                            paddingBottom: "2px",
                          }}
                        >
                          {" "}
                          copy Merchant Id
                        </button>
                      </div>
                    </div>
                  </div>
                  <Dropdown.Divider />
                  <div
                    style={{
                      background: "#F5F5F5",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span style={{ fontWeight: "600", padding: "10px" }}>
                      {" "}
                      Logged in as
                    </span>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        paddingLeft: "10px",
                      }}
                    >
                      <img
                        style={{ maxHeight: "25px", maxWidth: "25px" }}
                        src={userAvtar}
                      />
                      <span style={{ paddingLeft: "10px" }}>
                        {" "}
                        {state.myAccountInfo == null
                          ? ""
                          : state.myAccountInfo.email}
                      </span>
                    </div>

                    <Button variant="primary m-2" onClick={logOut}>
                      Log out
                    </Button>
                  </div>
                  <Dropdown.Divider />
                </Dropdown.Menu>
              </Dropdown>
            </ul>
            <button
              className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
              type="button"
              onClick={toggleOffcanvas}
            >
              <span className="mdi mdi-format-line-spacing"></span>
            </button>
          </div>
        </nav>
      )}
    </GlobalContext.Consumer>
  );
};

export default Navbar;
