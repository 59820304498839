import React from "react";
import CodeEditor from "./CodeEditor";
import { Tabs, Tab } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import './ApiViewer.css'
const exampleCode = `
import React, { useState } from "react";

function Example() {
  const [count, setCount] = useState(0);

  return (
    <div>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
    </div>
  );
}
`.trim();

const java =
  `RazorpayClient razorpay = new RazorpayClient("[YOUR_KEY_ID]", "[YOUR_KEY_SECRET]");

JSONObject request = new JSONObject();
request.put("name", <name>);
request.put("email", <email>);

Customer customer = razorpayClient.Customers.create(request);
`.trim();
const curl = `
curl -u [YOUR_KEY_ID]:[YOUR_KEY_SECRET] \
-X POST https://api.razorpay.com/v1/customers \
-H "Content-Type: application/json" \
-d '{
  "name":"Gaurav Kumar",
  "contact":"9123456780",
  "email":"gaurav.kumar@example.com",
  "fail_existing":"0",
  "gstin":"29XAbbA4369J1PA",
  "notes":{
    "notes_key_1":"Tea, Earl Grey, Hot",
    "notes_key_2":"Tea, Earl Grey… decaf."
  }
}'`.trim();

const python = `import razorpay
client = razorpay.Client(auth=("YOUR_ID", "YOUR_SECRET"))

client.customer.create({
  "name": "Gaurav Kumar",
  "contact": 9123456780,
  "email": "gaurav.kumar@example.com",
  "fail_existing": 0,
  "gstin": "29XAbbA4369J1PA",
  "notes": {
    "notes_key_1": "Tea, Earl Grey, Hot",
    "notes_key_2": "Tea, Earl Grey… decaf."
  }
})`.trim();
const php = `$api = new Api($key_id, $secret);

$api->customer->create(array('name' => 'Gaurav Kumar', 'email' => 'gaurav.kumar@example.com','contact'=>'9123456780',
'notes'=> array('notes_key_1'=> 'Tea, Earl Grey, Hot',
'notes_key_2'=> 'Tea, Earl Grey… decaf'));`.trim();

const dotNet =
  `RazorpayClient client = new RazorpayClient(your_key_id, your_secret);

Dictionary<string, object> options = new Dictionary<string,object>();

options.Add("name", "Gaurav Kumar"); 
options.Add("contact", "9123456780"); 
options.Add("email", "gaurav.kumar@example.com"); 
options.Add("fail_existing", 0); 

Customer customer = Customer.Create(options);`.trim();
const ruby = `require "razorpay"
Razorpay.setup('YOUR_KEY_ID', 'YOUR_SECRET')

customer = Razorpay::Customer.create email: 'gaurav.kumar@example.com', contact: '9123456780'
puts customer.id #cust_6vRXClWqnLhV14`.trim();
const node =
  `var instance = new Bumppy({ key_id: 'YOUR_KEY_ID', key_secret: 'YOUR_SECRET' })

instance.customer.create({
  name: "Suhas Suryavanshi",
  contact: 9100000000,
  email: "suhas5979@example.com",
  fail_existing: 0,
  gstin: "29XAbbA4369J1PA",
  notes: {
    notes_key_1: "Tea, Earl Grey, Hot",
    notes_key_2: "Tea, Earl Grey… decaf."
  }
})`.trim();
const ApiViewer = () => {
  return (
    <div style={{overflow:'scroll',padding:'0px'}}>
      <Tabs  defaultActiveKey="Webhooks" id="uncontrolled-tab-example">
        <Tab eventKey="curl" title="curl" className="test-tab">
          <CodeEditor code={curl} lang={"curl"} />
        </Tab>
        <Tab eventKey="Java" title="Java" className="test-tab">
          <CodeEditor code={java} lang={"java"} />
        </Tab>
        <Tab eventKey="Python" title="Python" className="test-tab">
          <CodeEditor code={python} lang={"python"} />
        </Tab>
        <Tab eventKey="PHP" title="PHP" className="test-tab">
          <CodeEditor code={php} lang={"php"} />
        </Tab>
        <Tab eventKey=".NET" title=".NET" className="test-tab">
          <CodeEditor code={dotNet} lang={"jsx"} />
        </Tab>
        <Tab eventKey="Ruby" title="Ruby" className="test-tab">
          <CodeEditor code={ruby} lang={"ruby"} />
        </Tab>
        <Tab eventKey="NodeJs" title="NodeJs" className="test-tab">
          <CodeEditor code={node} lang={"node"} />
        </Tab>
        <Tab eventKey="Response" title="Response" className="test-tab">
          <CodeEditor code={exampleCode} lang={"jsx"} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ApiViewer;
