import React from "react";
import ApiViewer from "./ApiViewer";

const exampleCode = `
import React, { useState } from "react";

function Example() {
  const [count, setCount] = useState(0);

  return (
    <div>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
    </div>
  );
}
`.trim();

const CustomerEntity = () => {
  return (
    <div style={{ display: "flex", paddingTop: "10px", paddingLeft: "10px" }}>
      <div style={{ width: "50vw" }}>
        <h2> Customers</h2>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontWeight: "400" }}>
            You can create customers with basic details such as name and contact
            details and use them for various payment dashboard solution
            offerings. Know more about customers.
          </span>
          <span>
            You can try out our APIs on the payment Postman Public Workspace.
          </span>
        </div>
        <div style={{ marginTop: "10px" }}>
          <h3> Customer Entity</h3>
          <Row
            first={"id"}
            second={
              "string Unique identifier of the customer. For example,cust_1Aa00000000004."
            }
          />
          <Row
            first={"name"}
            second={
              "string Customer's name. Alphanumeric, with period (.), apostrophe (') and parentheses allowed. Name must be between 3-50 characters in length. For example, Suhas Suryavanshi."
            }
          />
          <Row
            first={"contact"}
            second={
              "string The customer's phone number. Maximum length of 15 characters, inclusive of country code. For example, +919800000000."
            }
          />
          <Row
            first={"email"}
            second={
              "string The customer's email address. Maximum length of 64 characters. For example, suhas5979@example.com."
            }
          />
          <Row
            first={"gstin"}
            second={
              "string. GST number linked to the customer. For example, 29XAbbA4369J1PA."
            }
          />
          <Row
            first={"notes"}
            second={
              'This is a key-value pair that can be used to store additional information about the entity. It can hold a maximum of 15 key-value pairs, 256 characters (maximum) each. For example, "note_key": "Beam me up Scotty”.'
            }
          />
          <Row
            first={"created_at"}
            second={
              "integer. Timestamp, in Unix, when the customer was created. For example, 1234567890."
            }
          />
        </div>
      </div>
      <div style={{ width: "15px" }} />
      <ApiViewer />
    </div>
  );
};

const Row = ({ first, second }) => {
  return (
    <div
      style={{
        display: "flex",
        border: "1px solid #DDDDDD",
        borderRadius: "4px",
        padding: "6px",
      }}
    >
      <div style={{ flex: "1" }}>{first}</div>
      <div style={{ flex: "2" }}>{second}</div>
    </div>
  );
};

export default CustomerEntity;
