import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect, Link, BrowserRouter } from "react-router-dom";
import CustomerEntity from "./CustomerEntity";

import Test from "./DocumentationTest";
import AgentEntity from "./screens/AgentEntity";
import BeneficiaryEntity from "./screens/BeneficiaryEntity";
import GetAllAgentApiDoc from "./screens/GetAllAgentApiDoc";
import VirtualAccountEntity from "./screens/VirtualAccountEntity";
import VirtualPaymentEntity from "./screens/VirtualPaymentEntity";
import WebhookEntity from "./screens/WebhookEntity";

const DocumentationRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path={"/docs/api/customers/customer-entity"}
        component={CustomerEntity}
      />
      <Route
        exact
        path={"/docs/api/agents/agent-entity"}
        component={AgentEntity}
      />
      <Route
        exact
        path={"/docs/api/beneficiary/beneficiary-entity"}
        component={BeneficiaryEntity}
      />
      <Route
        exact
        path={"/docs/api/smart-collect/virtual-account-entity"}
        component={VirtualAccountEntity}
      />
      <Route
        exact
        path={"/docs/api/smart-collect/virtual-payment-entity"}
        component={VirtualPaymentEntity}
      />
      <Route
        exact
        path={"/docs/api/webhook/webhook-entity"}
        component={WebhookEntity}
      />
      <Route
        exact
        path={"/docs/api/agents/get-all-agents"}
        component={GetAllAgentApiDoc}
      />
    </Switch>
  );
};

export default DocumentationRoutes;
