import React from "react";
import ApiResponseViewer from "../ApiResponseViewer";
const res = `{
    "vId": 1,
    "accountNo": "45464700001001",
    "ifscCode": "YESB0CMSNOC",
    "mobileNo": "9540379702",
    "panNo": "AONPR1320R",
    "aadharNo": "1233344444",
    "createdBy": "Neeraj@example.com",
    "createdOn": null,
    "modifyBy": null,
    "modifyOn": null,
    "isActive": true
}`.trim();
const VirtualAccountEntity = () => {
  return (
    <div style={{ display: "flex", margin: "6px" }}>
      <div style={{ flex: "1" }}>
        <h3 style={{ margin: "16px" }}>Virtual Account Entity</h3>
        <div></div>
      </div>
      <div style={{ flex: "1" }}>
        <ApiResponseViewer response={res} />
      </div>
    </div>
  );
};

const Row = ({ first, second }) => {
  return (
    <div
      style={{
        display: "flex",
        border: "1px solid #DDDDDD",
        borderRadius: "4px",
        padding: "6px",
      }}
    >
      <div style={{ flex: "1" }}>{first}</div>
      <div style={{ flex: "2" }}>{second}</div>
    </div>
  );
};

export default VirtualAccountEntity;
