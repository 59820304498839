import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
// import { CLIENT } from "../../config";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { urls } from "../URLs";
import { basicAuth } from "../utils/Credentials";
import { data } from "./servicesData";

export const columns = [
  {
    dataField: "service_name",
    text: "service title",
    color: "green",
  },
  {
    dataField: "createdOn",
    text: "created on",
    color: "#000",
  },
  {
    dataField: "status",
    text: "Status",
    color: "#000",
  },
  {
    dataField: "action",
    text: "Action",
    color: "#000",
  },
];

const ServicesTable = ({ setDetails }) => {
  const { username, password } = basicAuth;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date= new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
//   const getAllVirtualPayments = async () => {
//     const accessToken = sessionStorage.getItem("accessToken");
//     var myHeaders = new Headers();
//     myHeaders.append("Authorization", `Bearer ${accessToken}`);
//     myHeaders.append("Content-Type", "application/json");
//     myHeaders.append(
//       "Cookie",
//       ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
//     );

//     var raw = JSON.stringify({
//       pageIndex: currentPage,
//       pageSize: 10,
//     });

//     var requestOptions = {
//       method: "POST",
//       headers: myHeaders,
//       body: raw,
//       redirect: "follow",
//     };
//     try {
//       const res = await fetch(
//         "https://apitranxt.paynnow.com/api/Payments/GetTransactionsall",
//         requestOptions
//       );
//       const data = await res.json();
//       console.log(data);
//       if (data && data.length > 0) {
//         const dataLoad = data.map((acc) => {
//           return {
//             ...acc,
//             upi: `${acc.beneAccountNo}@yesbankltd`,
//             date: new Date(acc.createdOn),
//           };
//         });
//         dataLoad.sort(function (a, b) {
//           return new Date(b.date) - new Date(a.date);
//         });
//         console.log(dataLoad);
//         setLedger(dataLoad);
//         setDataToDisplay(dataLoad);
//       }
//       // setPayments(JSON.parse(body).data);
//     } catch (error) {}
//   };

  // const getAllTransactions = async () => {
  //   try {
  //     var myHeaders = new Headers();
  //     myHeaders.append(
  //       "Authorization",
  //       "Basic " + Buffer.from(username + ":" + password).toString("base64")
  //     );
  //     myHeaders.append("Content-Type", "application/json");
  //     myHeaders.append(
  //       "Cookie",
  //       ".AspNetCore.Identity.Application=CfDJ8MOelGKz5SNAo4ulFnwVPLCTtT4YhHEdaew2jeJTJ5NTI_cdEAD9aevMAA-7GN9rZnVsA7IAd3FB6YH3WqwThM4QcivoYU7LO9cHTshosqjM8P_5rwD1_QYyI1gZ-JfS2WBaqMDJrwBrfTYXvmW5s4TZmjEE_vGE1zgfL13TIaYYlJTTSBVC_IxC27coe9jc_n-mhUcSfKx_q0wYrGF8pJRcpnnvECI22BDDnhKZja3Hb2ytiIT_T8Bjuud-iT8swNbKUwTK59dk619Mv8-KhNtxwY1rqZkhUYyswAmVg32jI3Bzu9GhoE0Y5h887fPuRV8R1Ct6MnGLlrePEneXuIjeKnw1Xxsppn7oj98pZkSD6RcmPMAheZan1FLMG02cN8zbU_oQ0T7_uzJ_JCaxgibxMnVCz-PAIgu54QJ4vuJnLqu-GDx7TBBCDQ8xjlQovaEuxToFNPpAKZYPp9lyi8DyDKo_uiljSTWyyqgU4W8LpK8YoZ042kpWpKsn3WmAKxJUbX2xnTnctSfZdJGjPGxVEY9Z3DBq9E1cLxKaq7T5clDU7jM1gyGgJN8MKXY0S7HYoywMABpBxX5TS-F63VlCoVLlITRctYOQFAM2koY3WKxHKkpfsSMY0AQrGM3loM5rRmKJbZutXvIN5UdDrc0_S6in3-7m5fnGi5I72ZtE"
  //     );

  //     var raw = JSON.stringify({
  //       fromDate: "2022-10-13",
  //       toDate: "2022-10-14",
  //     });

  //     var requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: "follow",
  //     };

  //     const res = await fetch(
  //       "https://apitranxt.paynnow.com/api/Payments/GetTransactions",
  //       requestOptions
  //     );
  //     const data = await res.json();
  //     console.log(data);
  //     if (data && data.length > 0) {
  //       setLedger(data);
  //       setDataToDisplay(data);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };
  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 9) / 10));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 9;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key] &&
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    console.log(filteredData);
    const startIdx = (currentPage - 1) * 10;
    const endIdx = currentPage * 10 - 1;
    console.log({ startIdx, endIdx });
    filteredData = filteredData.filter((bank, idx) => {
      console.log(idx, idx <= endIdx && idx >= startIdx);
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    console.log({ startIdx, endIdx });
  }, [currentPage]);

  useEffect(() => {
    // getAllTransactions();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + 9) / 10));
    const startIdx = 0;
    const endIdx = 9;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
   // getAllVirtualPayments();

   setLedger(data)
  }, []);
  console.log(ledger);
  return (
    <>
      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: "1" }}>
            <Form.Group style={{ maxWidth: "12rem", margin: "10px 0px" }}>
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </Form.Group>
          </div>
           {/* <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(`Report_${dateFormatter()}`, excelMapping(dataToDisplay,columns));
            }}
          >
            <img
              src={require("../../../assets/images/excel.png")}
              style={{ width: "1.6rem" }}
            />
            <strong style={{ marginLeft: "4px" }}>Export to Excel</strong>
          </div> */}
        </div>
      </div>

      <table style={{ width: "100%" }}>
        <tr style={{}}>
          <th style={{ fontSize: "0.9rem", border: "1px solid #dee2e6",padding:'16px 12px' }}>
            {" "}
            {"Sr"}
          </th>
          {columns.map((heading) => (
            <th style={{ fontSize: "0.9rem", border: "1px solid #dee2e6",padding:'16px 12px' }}>
              {heading.text}
            </th>
          ))}
        </tr>
        {dataToDisplay.map((e, ind) => (
          <tr>
            <td style={{ fontSize: "0.8rem", border: "1px solid #dee2e6" }}>
              {" "}
              {ind + 1}
            </td>
            {columns.map((cell) =>
              cell.dataField == "status" ? (
                <td style={{ fontSize: "1rem", border: "1px solid #dee2e6" }}>
                  <BootstrapSwitchButton
                    width={100}
                    checked={true}
                    onstyle="success"
                  />
                </td>
              ) : (
                <td
                  onClick={() => {
                    if (cell.dataField == "transferUniqueNo") {
                      setDetails(e);
                    }
                  }}
                  style={{
                    border: "1px solid #dee2e6",
                    fontSize: ".8rem",
                    color: cell.color,
                    cursor:
                      cell.dataField == "transferUniqueNo" ? "pointer" : "",
                  }}
                >
                  {e[cell.dataField]}
                </td>
              )
            )}
          </tr>
        ))}
      </table>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        <Button
          onClick={() => {
            callback(page + 1);
          }}
          variant="light"
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default ServicesTable;
