import React, { useState } from "react";
import { Form, Dropdown, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ContextMenuTrigger } from "react-contextmenu";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { urls } from "../URLs";

const banks = ["HDFC", "SBI", "ICICI"];
const paymentModes = [
  "Cards",
  "Upi/QR",
  "NetBanking",
  "EMI",
  "Wallet",
  "Pay Later",
];

const Topup = () => {
  const { topup: topupUrl } = urls;
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [paymentMode, setPaymentMode] = useState("Select Mode");
  const [remark, setRemark] = useState("");
  const [bank, setBank] = useState("Select Bank");
  const validate = () => {
    if (amount === "") {
      toast.error("Please Enter Amount");
      return false;
    } else if (transactionId === "") {
      toast.error("Please Enter Transaction ID");
      return false;
    } else if (paymentMode === "Select Mode") {
      toast.error("Please Select Payment Mode");
      return false;
    } else if (bank === "Select Bank") {
      toast.error("Please Select Bank");
      return false;
    }
    return true;
  };
  const submitRequest = async () => {
    if (!validate()) {
      return;
    }
    var myHeaders = new Headers();
    const accessToken = sessionStorage.getItem("accessToken");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      userId: 0,
      amount: parseInt(amount),
      isApproved: true,
      transactionId: transactionId,
      transactionDate: date,
      bankName: bank,
      paymentMode: 0,
      remarks: remark,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(topupUrl, requestOptions);
      console.log(res);
      const body = await res.text();
      console.log(body);
      toast.success("Topup  Successfull");
    } catch (error) {
      toast.error("Something went Wrong");
    }
  };
  return (
    <>
      <ToastContainer autoClose={3000} hideProgressBar draggableDirection="y" />

      <div style={{ borderRadius: "22px" }} className="card m-4 p-0">
        <h2
          style={{
            margin: "0",
            background: "#3676D8",
            padding: "12px",
            borderTopLeftRadius: "22px",
            color: "#FFFFFF",
            borderTopRightRadius: "22px",
            fontWeight: "bolder",
          }}
        >
          Topup
        </h2>
        <div className="card-body">
          <div
            style={{
              maxWidth: "35rem",
              width: "100%",
              background: "#FFF",
              padding: "10px",
              marginTop: "1rem",
              border:'1px solid #EEEEEE'
              // boxShadow:
              //   "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
            }}
            className="col-8"
          >
            {/* first */}
            <div className="container">
              <div className="row">
                <div className="col">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="form-control"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Amount"
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>

                <div className="col">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      className="form-control"
                      aria-label="Notes"
                      aria-describedby="basic-addon1"
                      placeholder="Transaction ID"
                      onChange={(e) => {
                        setTransactionId(e.target.value);
                      }}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
            {/* second */}
            <div className="container">
              <div className="row">
                <div className="col">
                  <Form.Group>
                    <Dropdown style={{ width: "100%" }}>
                      <Dropdown.Toggle
                        style={{ width: "100%", textAlign: "left" }}
                        variant="light"
                        id="dropdown-basic"
                      >
                        {bank}
                      </Dropdown.Toggle>

                      <Dropdown.Menu style={{ width: "100%" }}>
                        {banks.map((bank) => (
                          <Dropdown.Item
                            key={bank}
                            onClick={() => setBank(bank)}
                          >
                            {bank}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </div>
              </div>
            </div>
            {/* third */}
            <div className="container">
              <div className="row">
                <div className="col grid-margin">
                  <DatePicker
                    selected={date}
                    onChange={(date) => setDate(date)}
                    className="col"
                    placeholderText="Date"
                  />
                </div>
                <div className="col">
                  <Form.Group>
                    <Dropdown style={{ width: "100%", background: "#FFF" }}>
                      <Dropdown.Toggle
                        style={{ width: "100%", textAlign: "left" }}
                        variant="light"
                        id="dropdown-basic"
                      >
                        {paymentMode}
                      </Dropdown.Toggle>

                      <Dropdown.Menu style={{ width: "100%" }}>
                        {paymentModes.map((mode) => (
                          <Dropdown.Item
                            onClick={() => setPaymentMode(mode)}
                            key={mode}
                            href="#/action-1"
                          >
                            {mode}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                </div>
              </div>
            </div>

            {/* fourth */}
            <div className="container">
              <div className="row">
                <div className="col">
                  <Form.Group>
                    <textarea
                      placeholder="Remarks"
                      style={{ padding: "5px", width: "100%" }}
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
            {/* fifth */}
            {/* <div className="container">
              <div style={{ padding: "10px" }} className="row">
                <div className="col grid-margin"></div>
                <div className="col">
                  <Form.Group>
                    <Invalid text="Remark: Wallet Top up Approval takes 30 mins Balance is updated daily (9am to 8 pm) " />
                  </Form.Group>
                </div>
              </div>
            </div> */}
            {/* sixth */}
            <div className="container">
              <div className="row">
                <div className="col grid-margin"></div>
                <div className="col"></div>
                <div className="col">
                  <Button
                    onClick={submitRequest}
                    className="col"
                    variant="success"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Invalid = ({ text }) => {
  console.log("hiii");
  return (
    <div className="card-inverse-danger">
      <ContextMenuTrigger id="actionContextMenu">
        <div className="card-body">
          <p className="card-text">{text}</p>
        </div>
      </ContextMenuTrigger>
    </div>
  );
};

export default Topup;
