import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ExportToExcel } from "../utils/utilFuctions";
// import { CLIENT } from "../../config";
import { urls } from "../URLs";

export const columns = [
  {
    dataField: "agentId",
    text: "Agent ID",
    color: "green",
  },
  {
    dataField: "agentName",
    text: "Agent Name",
    color: "#000",
  },
  {
    dataField: "agentMob",
    text: "Mobile Number",
    color: "#000",
  },
  {
    dataField: "address",
    text: "Address",
    color: "#000",
  },
  //   {
  //     dataField: "upi",
  //     text: "UPI",
  //     color: "#000",
  //   },
  {
    dataField: "email",
    text: "Email",
    color: "#000",
  },
  {
    dataField: "createdOn",
    text: "Created On",
    color: "#000",
  },
];

const AgentTable = ({ setDetails }) => {
  const { getAgents: getAgentsUrl } = urls;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date= new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };

  const getAgents = async () => {
    var myHeaders = new Headers();
    const accessToken = sessionStorage.getItem("accessToken");
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-ixWTq2CMqneGqLqb-BbP9Ujnfo1G38wJ_i-9LVAbvcAN4min4BcXzKCGmTGAa648RwBaLjSiVYGzQBfPMDszUXpHCecxCWfqG1j1zKy6jvUSYSr6A7FXTmlyrCd8txoSNXDJPngd0vPqIFJ2iGtxv3_EiQLO3C9mABt0vchwZSkoXRSVZATUhNt-VDDkh9bZkQcVp1p3kT6g1WCRanTjNQ4IRBLeNlBcBF_3xJafe36ka45O019gYptNpJ6OuYvA9UWnezm--9024h4O9jg1nTd_GbmrQ5_hvRoLjw1lVInkM9x4ERQ4dl1nUqk-ctME831pj7wlPL-3zHszBGfJJ2vT0oMgvTy8vSAjpNsRMp76eL1aTvzaNCD2n7OOGvVX3uHIEdSF1N-1hFqgBhAUp80diHtIlgAkQccV4cHpmofI2tknA-xRa2WdexcuqWSxtbdV0TTsMzPjbnUmFrya8abhTtl08ADcvGGwEaQYDWpgACynKyjEPNJPo5AM527eLMEg_Q8lCcR4-Lw6DJzdpk3A6kui1ykczTXB84gvFZsrs-tEb9jI3xIyHb05eBTS1pJM_CmYDV3vO414IdCkDSQt1JFp8l5f2B5Yk8urVOzDMetJa2rq_v5QXcP6NeID5EwqpCNanBIHoDpcW_pnbxg"
    );

    var raw = JSON.stringify({
      pageIndex: 1,
      pageSize: 10000,
      userId: 0,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(getAgentsUrl, requestOptions);
      const data = await res.json();
      if (data.data && data.data.length > 0) {
        const dataLoad = data.data.map((acc) => {
          return {
            ...acc,
            upi: `${acc.beneAccountNo}@yesbankltd`,
            date: new Date(acc.createdOn),
          };
        });
        dataLoad.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });
        console.log(dataLoad);
        setLedger(dataLoad);
        // setDataToDisplay(dataLoad);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 9) / 10));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 9;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  //   useEffect(() => {
  //     let filteredData = ledger.filter((bank) => {
  //       let b = false;
  //       if (ledger.length > 0) {
  //         for (let key in ledger[0]) {
  //           if (
  //             bank[key] &&
  //             bank[key].toString().toLowerCase().includes(searchText.toLowerCase())
  //           ) {
  //             b = true;
  //           }
  //         }
  //       }
  //       return b;
  //     });
  //     console.log(filteredData);
  //     const startIdx = (currentPage - 1) * 10;
  //     const endIdx = currentPage * 10 - 1;
  //     console.log({ startIdx, endIdx });
  //     filteredData = filteredData.filter((bank, idx) => {
  //       console.log(idx, idx <= endIdx && idx >= startIdx);
  //       return idx <= endIdx && idx >= startIdx;
  //     });
  //     setDataToDisplay(filteredData);
  //     console.log({ startIdx, endIdx });
  //   }, [currentPage]);

  useEffect(() => {
    getAgents();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + 9) / 10));
    const startIdx = 0;
    const endIdx = 9;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAgents();
  }, []);
  console.log(ledger);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "10px 0px",
            width: "100%",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="daterange-picker"
          >
            <Form.Group className="rounded-0">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="form-control rounded-0"
                placeholderText="Start Date"
              />
            </Form.Group>

            <Form.Group>
              <span className="mx-2"> {" To "} </span>
            </Form.Group>
            <Form.Group style={{ marginLeft: "10px" }}>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                className="form-control rounded-0"
                placeholderText="Start Date"
              />
            </Form.Group>
          </div>
          <Form.Group style={{ marginLeft: "10px" }}>
            <Button className="rounded-0" variant="success" onClick={getAgents}>
              {"Search"}
            </Button>
          </Form.Group>
          <Form.Group className="ml-auto" style={{ maxWidth: "12rem" }}>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="Search"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </Form.Group>
        </div>
      </div>
      {/* <div style={{ width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: "1" }}>
            <Form.Group style={{ maxWidth: "12rem" }}>
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </Form.Group>
          </div>
        </div>
      </div> */}
     
     
      <table style={{ width: "100%" }}>
        <tr>
          <th style={{ fontSize: "0.9rem" }}> {"Sr"}</th>
          {columns.map((heading) => (
            <th style={{ fontSize: "0.9rem" }}>{heading.text}</th>
          ))}
        </tr>
        {dataToDisplay.map((e, ind) => (
          <tr>
            <td style={{ fontSize: "0.8rem" }}> {ind + 1}</td>
            {columns.map((cell) =>
              cell.dataField == "status" ? (
                e[cell.dataField] == "success" ? (
                  <td style={{ fontSize: "0.8rem" }}>
                    {" "}
                    <label
                      style={{
                        padding: "4px",
                        borderRadius: "4px",
                        minWidth: "4rem",
                      }}
                      className="badge badge-success"
                    >
                      {"Success"}
                    </label>{" "}
                  </td>
                ) : (
                  <td
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    <label
                      style={{
                        padding: "4px",
                        borderRadius: "4px",
                        minWidth: "4rem",
                      }}
                      className="badge badge-warning"
                    >
                      {e[cell.dataField]}
                    </label>
                  </td>
                )
              ) : (
                <td
                  onClick={() => {
                    if (cell.dataField == "agentId") {
                      setDetails(e);
                    }
                  }}
                  style={{
                    fontSize: ".8rem",
                    color: cell.color,
                    cursor: cell.dataField == "agentId" ? "pointer" : "",
                  }}
                >
                  {e[cell.dataField]}
                </td>
              )
            )}
          </tr>
        ))}
      </table>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        <Button
          onClick={() => {
            callback(page + 1);
          }}
          variant="light"
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default AgentTable;
