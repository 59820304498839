export const data = [
  {
    service_name: "DMT",
    createdOn: "2020-11-11",
    status: true,
    action: "",
  },
  {
    service_name: "DMT Express",
    createdOn: "2020-11-11",
    status: true,
    action: "",
  },
  {
    service_name: "DMT Full KYC",
    createdOn: "2020-11-11",
    status: true,
    action: "",
  },
  {
    service_name: "AEPS",
    createdOn: "2020-11-11",
    status: true,
    action: "",
  },
  {
    service_name: "AadharPay",
    createdOn: "2020-11-11",
    status: true,
    action: "",
  },
  {
    service_name: "BBPS Onlinle",
    createdOn: "2020-11-11",
    status: true,
    action: "",
  },
  {
    service_name: "BBPS Offline",
    createdOn: "2020-11-11",
    status: true,
    action: "",
  },
  {
    service_name: "Virtual Colllection",
    createdOn: "2020-11-11",
    status: true,
    action: "",
  },
  {
    service_name: "QR Code",
    createdOn: "2020-11-11",
    status: true,
    action: "",
  },
  {
    service_name: "Payouts",
    createdOn: "2020-11-11",
    status: true,
    action: "",
  },
  {
    service_name: "Micro ATM",
    createdOn: "2020-11-11",
    status: true,
    action: "",
  },
  {
    service_name: "CMS",
    createdOn: "2020-11-11",
    status: true,
    action: "",
  },
  {
    service_name: "Fastag",
    createdOn: "2020-11-11",
    status: true,
    action: "",
  },
  {
    service_name: "Insurance",
    createdOn: "2020-11-11",
    status: true,
    action: "",
  },
  {
    service_name: "LIC",
    createdOn: "2020-11-11",
    status: true,
    action: "",
  },
];
