import React, { useContext, useState, useEffect, useRef } from "react";
import { Tabs, Tab, Form, Dropdown, Button, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import Sidebarnew from "../smart collect/Sidebarnew";
import { urls } from "../URLs";
import AgentSideBar from "./AgentSideBar";
import AgentTable from "./AgentTable";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
const { SearchBar } = Search;

const userTypes = [
  { label: "Master Distributor", value: "3" },
  { label: "Distributor", value: "2" },
  { label: "Retailer", value: "5" },
  { label: "API Partner", value: "9" },
];

const tariff = [{ label: "Bumppy", value: "3" }];

const paymentModes = [
  { label: "Cash Deposite", value: "3" },
  { label: "UPI/IMPS", value: "2" },
  { label: "NEFT/RTGS", value: "5" },
  { label: "CDM", value: "9" },
  { label: "Fund Transfer", value: "8" },
  { label: "CHEQUE", value: "null" },
];

const whiteStyle = { background: "#FFF" };
const greyStyle = { background: "#e7eaf6" };

const Agents = () => {
  const [date, setDate] = useState(new Date());
  const [amount, setAmount] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [paymentMode, setPaymentMode] = useState("Select Mode");
  const [remark, setRemark] = useState("");
  const [bank, setBank] = useState("Select Bank");
  const [virtualAccount, setVirtualAccount] = useState(null);
  const [List, setList] = useState([]);
  const [File, setFile] = useState("");
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);
  const [enableSmartCollectButtonState, setEnableSmartCollectButtonState] =
    useState(true);
  const [accountCreated, setAccountCreated] = useState(
    localStorage.getItem("va-created") || false
  );
  const [TopupData, setTopupData] = useState({
    msg: "Remark: Wallet Top up Approval takes upto 30 mins. Balance is updated daily(9 AM to 8 PM).",
    success: false,
  });
  const [recordLoading, setRecordLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [reports, setReports] = useState([]);

  return (
    <div style={{ padding: "2rem" }}>
      <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo-mini" to="/">
          <img src={require("../../assets/images/logo-mini.svg")} alt="logo" />
        </Link>
      </div>
      <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        <AgentSideBar details={details} />
      </div>
      <div style={{ borderRadius: "22px" }} className="card m-4 p-0">
        <h2
          style={{
            margin: "0",
            background: "#3676D8",
            padding: "12px",
            borderTopLeftRadius: "22px",
            color: "#FFFFFF",
            borderTopRightRadius: "22px",
            fontWeight: "bolder",
          }}
        >
          Agents
        </h2>
        <div className="card-body">
          <Tabs defaultActiveKey="View" id="uncontrolled-tab-example">
            <Tab eventKey="View" title="View" className="test-tab">
              <div>
                <div
                  style={{
                    padding: "12px",
                    background: "#3676D8",
                    color: "#FFF",
                    fontWeight: "bolder",
                  }}
                >
                  View Agent
                </div>

                <AgentTable
                  setDetails={(details) => {
                    setDetails({ ...details, sidebarType: "agents" });
                    document
                      .querySelector(".content-wrapper")
                      .classList.toggle("sidebar-icon-only");
                  }}
                />
              </div>
            </Tab>

            <Tab eventKey="Create" title="Create" className="test-tab">
              <div>
                <div
                  style={{
                    padding: "12px",
                    background: "#3676D8",
                    color: "#FFF",
                    fontWeight: "bolder",
                  }}
                >
                  Create Agent
                </div>

                <div>
                  <div className="row p-3">
                    <span>Personal Info</span>
                  </div>
                  <div className="row">
                    <div className="col p-0 mr-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Select User Type`}
                        </Form.Label>
                        <Select
                          options={userTypes}
                          onChange={(e) => {
                            setPaymentMode(e);
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col p-0 mr-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Enter Full Name`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Full Name"
                          onChange={(e) => {
                            setAmount(e.target.value);
                          }}
                          style={{ borderRadius: "0px" }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col p-0 mr-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Enter Mobile`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Mobile"
                          onChange={(e) => {
                            setTransactionId(e.target.value);
                          }}
                          style={{ borderRadius: "0px" }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col p-0 mr-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Enter Email ID`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Email ID"
                          onChange={(e) => {
                            setAmount(e.target.value);
                          }}
                          style={{ borderRadius: "0px" }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col p-0 mr-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Enter Shop Name`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Shop Name"
                          onChange={(e) => {
                            setTransactionId(e.target.value);
                          }}
                          style={{ borderRadius: "0px" }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="row p-3">
                    <span>Attach Tariff Info</span>
                  </div>
                  <div className="row">
                    <div className="col p-0 mr-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`DMT Tariff`}
                        </Form.Label>
                        <Select
                          options={tariff}
                          onChange={(e) => {
                            setPaymentMode(e);
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col p-0 mr-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`AEPS Tariff`}
                        </Form.Label>
                        <Select
                          options={tariff}
                          onChange={(e) => {
                            setPaymentMode(e);
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col p-0 mr-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Other Tariff`}
                        </Form.Label>
                        <Select
                          options={tariff}
                          onChange={(e) => {
                            setPaymentMode(e);
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col p-0">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Select Sales Team`}
                        </Form.Label>
                        <Select
                          options={userTypes}
                          onChange={(e) => {
                            setPaymentMode(e);
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="row p-3">
                    <span>{"Address & Document Info"}</span>
                  </div>
                  <div className="row">
                    <div className="col p-0 ml-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Pin Code`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Pin Code"
                          onChange={(e) => {
                            setAmount(e.target.value);
                          }}
                          style={{ borderRadius: "0px" }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col p-0 ml-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Shop Address`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Shop Address"
                          onChange={(e) => {
                            setTransactionId(e.target.value);
                          }}
                          style={{ borderRadius: "0px" }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col p-0 ml-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Aadhar Number`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Aadhar Number"
                          onChange={(e) => {
                            setAmount(e.target.value);
                          }}
                          style={{ borderRadius: "0px" }}
                        />
                      </Form.Group>
                    </div>

                    <div className="col p-0 ml-2">
                      <Form.Group>
                        <Form.Label>
                          <span style={{ color: "red" }}>*</span>
                          {`Pancard Number`}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          aria-label="Notes"
                          aria-describedby="basic-addon1"
                          placeholder="Enter Pancard Number"
                          onChange={(e) => {
                            setTransactionId(e.target.value);
                          }}
                          style={{ borderRadius: "0px" }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col px-0">
                      <Button className="rounded-0 btn-block" variant="success">
                        {" "}
                        Submit
                      </Button>
                    </div>
                    <div className="col">
                      <Button className="rounded-0 btn-block" variant="danger">
                        {" "}
                        Clear
                      </Button>
                    </div>
                    <div className="col p-0"></div>
                    <div className="col p-0"></div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
const EmptyArrayHandler = () => {
  return (
    <div className="row">
      <div className="col-12">
        <span style={{ fontSize: "1.4rem" }}>No records Found</span>
      </div>
    </div>
  );
};
export default Agents;
