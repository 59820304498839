import React, { Component, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

const Sidebar22 = {
  position: "fixed",
  right: "0",
  width: "0px",
  height: "600px",
  overflow: "scroll",
  background: "#FFF",
};
const options = [
  { value: "chocolate", label: "virtual_account.credited" },
  { value: "strawberry", label: "virtual_account.created" },
  { value: "vanilla", label: "virtual_account.closed" },
];

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: "#FFF",
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    borderColor: state.isFocused ? "blue" : "green",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      borderColor: state.isFocused ? "green" : "blue",
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
};

function camelToSentence(text) {
  var result = text.replace(/([A-Z])/g, " $1");
  var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar2").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });
  }
  toggle() {
    document
      .querySelector(".content-wrapper")
      .classList.toggle("sidebar-icon-only");
    this.props.callback(true);
  }

  render() {
    var infoElements = [];
    if (typeof this.props.details !== "undefined") {
      Object.entries(this.props.details).map(([key, value]) => {
        infoElements.push(
          <BorderLessCard
            label={`${camelToSentence(key)}`}
            value={`${value}`}
          />
        );
      });
      var AccountNumber = "";
    }
    console.log(this.props.details);
    return (
      <nav
        className="side-sheet sidebar Sidebar23"
        id="sidebar2"
        style={Sidebar22}
      >
        <div style={{ padding: "12px" ,paddingBottom:'0px'}} className="row">
          <div className="col">
            <h4> Webhook Details</h4>
          </div>
          <div className="col">
            <div style={{ padding: "0px"}} className="row">
              <div style={{ marginRight: "10px" }} className="div">
                <Button variant="outline-primary">Delete</Button>
              </div>
              <div className="div">
                <Button onClick={this.toggle.bind(this)} variant="primary">
                  Edit
                </Button>
              </div>
              <div style={{ }} className="col">
                <span
                  style={{
                    fontSize: "2rem",
                    display: "flex",
                    color: "#b2b2b3",
                    justifyContent: "end",
                    ariaHidden: "true",
                    paddingRight: "4px",
                    cursor: "pointer",

                  }}
                  onClick={() => {
                    document
                      .querySelector(".content-wrapper")
                      .classList.toggle("sidebar-icon-only");
                  }}
                >
                  &times;
                </span>
              </div>
            </div>
          </div>
        </div>
        <Dropdown.Divider />

        <div className="col grid-margin stretch-card">
          <div style={{ padding: "0px" }} className="card">
            {infoElements.map((e) => e)}
          </div>
        </div>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector(".content-wrapper");
    document.querySelectorAll(".sidebar2 .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

const BorderLessCard = ({ label, value, badge }) => {
  return (
    <div style={{ borderRadius: "0px", padding: "8px" }}>
      <div style={{ padding: "0px" }} className="row">
        <div className="col">
          <span style={{ color: "#8991ae" }}>{label}</span>
        </div>
        <div className="col">{badge ? badge : <span> {value}</span>}</div>
      </div>
    </div>
  );
};

const DetailHeaderCard = ({ label, value }) => {
  return (
    <div
      style={{
        borderWidth: "0px",
        background: "#e7eaf6",
        borderRadius: "0px",
        padding: "8px",
      }}
      className="card"
    >
      <div style={{ padding: "0px" }} className="row">
        <div className="col">
          <span>{label}</span>
        </div>
        <div className="col">
          <span> {value}</span>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
