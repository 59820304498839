import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
 import { ExportToExcel } from "../utils/utilFuctions";
// import { CLIENT } from "../../config";
import { urls } from "../URLs";
import { excelMapping } from "../utils/utilFuctions";

export const columns = [
  {
    dataField: "transferUniqueNo",
    text: "Transaction Number",
    color: "green",
  },
  {
    dataField: "beneAccountNo",
    text: "Beneficiary Account Number",
    color: "#000",
  },
  {
    dataField: "beneAccountIfsc",
    text: "IFSC Code",
    color: "#000",
  },
  {
    dataField: "beneFullName",
    text: "Beneficiary Name",
    color: "#000",
  },
  {
    dataField: "upi",
    text: "UPI",
    color: "#000",
  },
  {
    dataField: "transferAmt",
    text: "Amount",
    color: "green",
  },
  {
    dataField: "createdOn",
    text: "Created On",
    color: "#000",
  },
];

const SmartCollectPaymentsTable = ({ setDetails }) => {
  const { createVirtualAccount: createVirtualAccountUrl } = urls;
  const { getAllVirtualAccounts: getAllVirtualAccountsUrl } = urls;
  const { getAllVirtualPayments: getAllVirtualPaymentsUrl } = urls;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [pages, setPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [ledger, setLedger] = useState([]);

  function getDatePreviousMonth(date = new Date()) {
    date.setMonth(date.getMonth() - 1);
    return date;
  }
  const dateFormatter = (date = new Date()) => {
    return `${date.getFullYear()}-${
      date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
    }-${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}`;
  };
  const getAllVirtualPayments = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iwOgwEj6EBN0jpchgFhuiV_O6Tcuf6QLM3cjarWosB3o17JTbrLuvcOmIJGLw0OwV-JJr2mhON-MIsfNGVplhmoJMIoQQrIyv6dzlCW8_eOvZw0ahT_icJcGsoHfR-1FLppZoY2SWzX8NUi9aabidVqbnsJjig4BpI2oPpnu73FH5iMh2Kfuz2xWcPSdx08C7D_6Ygts4I9XapyCvm0OVKguJ3fWPFHBgoY-PSnQ4KNzeh-OI4TzaffhkWCBwgBLtA6ybohQ1cqY5c2Y2EPo-sgAft8xvCDg9pibGVqcUdtEkqHj-BKKBdLijXEW-P7Q9e-T-wL07LWdnOnMPF9YCns79c8kBgyHTiVi82lFdqqIZtzZnhJsZoVhf9vsGaD37-6zhe4sDOkUJf4WCLur6gGQB8LQrfK9RZg30X0OvMv7ZCOtC4SKxgXiyk44NtkqY9oP_cVSb00F_E80WkQhk1A4nUfPalYivPP3EPF45TtK2hlhfdvdw_G1rMLrDiTbaIj-yvW5831ualRbsVUhVSHiUZzLTshMjUg-r_o5FNk4OSR2EyJm2l1WGwLdtZccqzvuyG_UzV9XiIYA658Nqy0iQX-ckKK4yR0yLPGXkmyx1MzMh9RjVPJwsRmhnIKqPE4eOFQNdJDYowJQhEQIMZV"
    );

    var raw = JSON.stringify({
      pageIndex: currentPage,
      pageSize: 10,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const res = await fetch(getAllVirtualPaymentsUrl, requestOptions);
      const data = await res.json();
      if (data.data && data.data.length > 0) {
        const dataLoad = data.data.map((acc) => {
          return {
            ...acc,
            upi: `${acc.beneAccountNo}@yesbankltd`,
            date: new Date(acc.createdOn),
          };
        });
        dataLoad.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });
        console.log(dataLoad);
        setLedger(dataLoad);
        // setDataToDisplay(dataLoad);
      }
      // setPayments(JSON.parse(body).data);
    } catch (error) {}
  };

  const openInNewTab = () => {
    const newWindow = window.open(
      `/pages/print?id=090422191784660&type=DMT`,
      "",
      "height=600,width=800'"
    );
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    let filteredData = ledger.filter((bank) => {
      let b = false;
      if (ledger.length > 0) {
        for (let key in ledger[0]) {
          if (
            bank[key]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
          ) {
            b = true;
          }
        }
      }
      return b;
    });
    setPages(Math.floor((filteredData.length + 9) / 10));
    setTotal(filteredData.length);
    const startIdx = 0;
    const endIdx = 9;
    filteredData = filteredData.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
    setCurrentPage(1);
  }, [searchText]);

  //   useEffect(() => {
  //     let filteredData = ledger.filter((bank) => {
  //       let b = false;
  //       if (ledger.length > 0) {
  //         for (let key in ledger[0]) {
  //           if (
  //             bank[key] &&
  //             bank[key].toString().toLowerCase().includes(searchText.toLowerCase())
  //           ) {
  //             b = true;
  //           }
  //         }
  //       }
  //       return b;
  //     });
  //     console.log(filteredData);
  //     const startIdx = (currentPage - 1) * 10;
  //     const endIdx = currentPage * 10 - 1;
  //     console.log({ startIdx, endIdx });
  //     filteredData = filteredData.filter((bank, idx) => {
  //       console.log(idx, idx <= endIdx && idx >= startIdx);
  //       return idx <= endIdx && idx >= startIdx;
  //     });
  //     setDataToDisplay(filteredData);
  //     console.log({ startIdx, endIdx });
  //   }, [currentPage]);

  useEffect(() => {
    getAllVirtualPayments();
  }, [currentPage]);

  useEffect(() => {
    setPages(Math.floor((ledger.length + 9) / 10));
    const startIdx = 0;
    const endIdx = 9;
    setTotal(ledger.length);
    const filteredData = ledger.filter((bank, idx) => {
      return idx <= endIdx && idx >= startIdx;
    });
    setDataToDisplay(filteredData);
  }, [ledger]);

  // did mount
  useEffect(() => {
    getAllVirtualPayments();
  }, []);
  console.log(ledger);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", margin: "10px 0px" }}
        >
          <div className="daterange-picker">
            <Form.Group style={{ marginLeft: "10px" }}>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="form-control"
                placeholderText="Start Date"
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>

            <Form.Group>
              <span style={{ padding: "10px" }}> {" To "} </span>
            </Form.Group>
            <Form.Group style={{ marginLeft: "10px" }}>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                className="form-control"
                placeholderText="Start Date"
                style={{ borderRadius: "0px" }}
              />
            </Form.Group>
          </div>
          {/* <Form.Group style={{ marginLeft: "10px" }}>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="By Remmiter Mob No"
            />
          </Form.Group>
          <Form.Group style={{ marginLeft: "10px" }}>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="By Account No"
            />
          </Form.Group>
          <Form.Group style={{ marginLeft: "10px" }}>
            <Form.Control
              type="text"
              className="form-control rounded-0"
              aria-label="Notes"
              aria-describedby="basic-addon1"
              placeholder="By Transaction ID"
            />
          </Form.Group> */}
          <Form.Group style={{ marginLeft: "10px" }}>
            <Button
              className="rounded-0"
              variant="success"
              onClick={getAllVirtualPayments}
            >
              {"Search"}
            </Button>
          </Form.Group>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: "1" }}>
            <Form.Group style={{ maxWidth: "12rem", margin: "10px 0px" }}>
              <Form.Control
                type="text"
                className="form-control rounded-0"
                aria-label="Notes"
                aria-describedby="basic-addon1"
                placeholder="Search"
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                value={searchText}
              />
            </Form.Group>
          </div>
           <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              ExportToExcel(`Report_${dateFormatter()}`, excelMapping(dataToDisplay,columns));
            }}
          >
            <img
              src={require("../../assets/images/excel.png")}
              style={{ width: "1.6rem" }}
            />
            <strong style={{ marginLeft: "4px" }}>Export to Excel</strong>
          </div>
        </div>
      </div>
      <table style={{ width: "100%" }}>
        <tr>
          <th style={{ fontSize: "0.9rem" }}> {"Sr"}</th>
          {columns.map((heading) => (
            <th style={{ fontSize: "0.9rem" }}>{heading.text}</th>
          ))}
        </tr>
        {dataToDisplay.map((e, ind) => (
          <tr>
            <td style={{ fontSize: "0.8rem" }}> {ind + 1}</td>
            {columns.map((cell) =>
              cell.dataField == "status" ? (
                e[cell.dataField] == "success" ? (
                  <td style={{ fontSize: "0.8rem" }}>
                    {" "}
                    <label
                      style={{
                        padding: "4px",
                        borderRadius: "4px",
                        minWidth: "4rem",
                      }}
                      className="badge badge-success"
                    >
                      {"Success"}
                    </label>{" "}
                  </td>
                ) : (
                  <td
                    style={{
                      fontSize: "0.8rem",
                    }}
                  >
                    <label
                      style={{
                        padding: "4px",
                        borderRadius: "4px",
                        minWidth: "4rem",
                      }}
                      className="badge badge-warning"
                    >
                      {e[cell.dataField]}
                    </label>
                  </td>
                )
              ) : (
                <td
                  onClick={() => {
                    if (cell.dataField == "transferUniqueNo") {
                      setDetails(e);
                    }
                  }}
                  style={{
                    fontSize: ".8rem",
                    color: cell.color,
                    cursor:
                      cell.dataField == "transferUniqueNo" ? "pointer" : "",
                  }}
                >
                  {e[cell.dataField]}
                </td>
              )
            )}
          </tr>
        ))}
      </table>
      <Pagination
        page={currentPage}
        limit={pages}
        callback={(page) => {
          setCurrentPage(page);
        }}
        count={dataToDisplay.length}
        total={total}
      />
    </>
  );
};

const Pagination = ({ page, limit, callback, total, count }) => {
  console.table({ page, limit, callback });
  return (
    <div
      className="row"
      style={{
        display: "flex",
        alignItems: "end",
        width: "100%",
        margin: "10px 0px",
        justifyContent: "space-between",
      }}
    >
      <div>
        <strong>{`Showing ${count} of ${total} entries`}</strong>
      </div>
      <div>
        {page - 2 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {"Previous"}
          </Button>
        ) : null}

        {page - 1 > 0 ? (
          <Button
            onClick={() => {
              callback(page - 1);
            }}
            variant="light"
          >
            {" "}
            {(page - 1).toString()}
          </Button>
        ) : null}
        <Button variant="primary"> {page.toString()}</Button>
        <Button
          onClick={() => {
            callback(page + 1);
          }}
          variant="light"
        >
          {"Next"}
        </Button>
      </div>
    </div>
  );
};

export default SmartCollectPaymentsTable;
