import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";

const Sidebar22 = {
  position: "fixed",
  right: "0",
  width: "0px",
  height: "600px",
  overflow: "scroll",
  background: "#FFF",
};

function camelToSentence(text) {
  var result = text.replace(/([A-Z])/g, " $1");
  var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar2").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });
  }

  render() {
    var infoElements = [];
    var id,
      AccountDescription,
      CreatedOn,
      basePrice,
      status,
      AccountNumber,
      BenificiaryName,
      IfscCode,
      UpiId,
      AmmountPaid,
      CustomerId,
      CloseBy,
      Notes,
      mobileNo,
      city,
      pincode,
      title,
      address,
      agentId,
      agentName,
      beneficiaryId,
      customerMob,
      customerName,
      channel,
      country,
      name,
      partnerId,
      title;
    if (typeof this.props.details !== "undefined") {
      Object.entries(this.props.details).map(([key, value]) => {
        if (value && value.toString().length > 2) {
          infoElements.push(
            <BorderLessCard
              label={`${camelToSentence(key)}`}
              value={`${value}`}
            />
          );
        }
      });
      var details = this.props.details;
      id = details.id;
      AccountDescription = details.AccountDescription;
      CreatedOn = details.CreatedOn;
      basePrice = details.basePrice;
      AccountNumber = details.benefAccNo;
      BenificiaryName = details.BenificiaryName;
      IfscCode = details.benefIfscCode;
      UpiId = details.UpiId;
      AmmountPaid = details.AmmountPaid;
      CustomerId = details.CustomerId;
      CloseBy = details.CloseBy;
      Notes = details.Notes;
      mobileNo = details.benefMob;
      city = details.city;
      pincode = details.pincode;
      title = details.title;
      address = details.address1;
      agentId = details.agentId;
      agentName = details.agentName;
      beneficiaryId = details.beneficiaryId;
      customerMob = details.customerMob;
      customerName = details.customerName;
      channel = details.channel;
      country = details.country;
      name = details.name;
      partnerId = details.partnerId;
    }
    console.log(this.props.details);
    return (
      <nav
        className="side-sheet sidebar Sidebar23"
        id="sidebar2"
        style={Sidebar22}
      >
        <div
          style={{ padding: "0px", marginLeft: "10px", marginRight: "10px" }}
          className="row"
        >
          <div style={{ padding: "6px" }} className="col">
            <span style={{ marginLeft: "4px" }}>
              <i className="fa fa-bank"></i>
            </span>
            <span> {id ? id : ""}</span>
          </div>
          <div style={{ paddingRight: "0px" }} className="col">
            <span
              style={{
                fontSize: "2rem",
                display: "flex",
                color: "#b2b2b3",
                justifyContent: "end",
                ariaHidden: "true",
                paddingRight: "4px",
                cursor: "pointer",
              }}
              onClick={() => {
                document
                  .querySelector(".content-wrapper")
                  .classList.toggle("sidebar-icon-only");
              }}
            >
              &times;
            </span>
          </div>
        </div>
        <Dropdown.Divider />

        <div className="col grid-margin stretch-card">
          <div style={{ padding: "0px" }} className="card">
            <DetailHeaderCard label="Account Details" value="Copy Details" />

            {infoElements.map((e) => e)}
          </div>
        </div>
        <div className="card-body">
          <div className="template-demo">
            <Button variant="outline-primary">Close</Button>
          </div>
        </div>

        {/* <div className="row">
          <div className="col">
            <p className="card-title">Payments to this account - 0 payments</p>
          </div>
          <div className="col">
            <button type="button" className="btn btn-link ">
              View Account Details....
            </button>
          </div>
        </div> */}
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector(".content-wrapper");
    document.querySelectorAll(".sidebar2 .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

const BorderLessCard = ({ label, value, badge }) => {
  return (
    <div style={{ borderRadius: "0px", padding: "8px" }}>
      <div style={{ padding: "0px" }} className="row">
        <div className="col">
          <span style={{ color: "#8991ae" }}>{label}</span>
        </div>
        <div className="col">{badge ? badge : <span> {value}</span>}</div>
      </div>
    </div>
  );
};

const DetailHeaderCard = ({ label, value }) => {
  return (
    <div
      style={{
        borderWidth: "0px",
        background: "#e7eaf6",
        borderRadius: "0px",
        padding: "8px",
      }}
      className="card"
    >
      <div style={{ padding: "0px" }} className="row">
        <div className="col">
          <span>{label}</span>
        </div>
        <div className="col">
          <span> {value}</span>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
