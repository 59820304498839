import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import "./Bar.css";
const style = {
  color: "#FFF",
  background: "#0000FF",
};
const activeStyle = {
  color: "#0000FF",
  fontWeight: "bold",
  background: "#0000FF",
};
const textColorActive = {
  color: "##00bfff",
  cursor: "pointer",
};
const textColorInActive = {
  color: "#000000",
  cursor: "pointer",
};

class DocumentationSidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/docs/api/customers", state: "customers" },
      { path: "/docs/api/guide", state: "apiGuide" },
      { path: "/docs/api/agents", state: "agents" },
      { path: "/docs/api/beneficiary", state: "beneficiary" },
      { path: "/docs/api/smart-collect", state: "smartCollect" },
      { path: "/docs/api/topup", state: "topup" },
      { path: "/docs/api/webhook", state: "webhook" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    console.log(this.state);
    return (
      <nav
        style={{ background: "#F5F5FA", minHeight: "100vh" }}
        className="sidebar sidebar-offcanvas"
        id="sidebar"
      >
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="index.html">
            <strong style={{ color: "#FFF" }}>BUMPPY</strong>
          </a>
          <a className="sidebar-brand brand-logo-mini" href="index.html">
            <img
              src={require("../../assets/images/logo-mini.svg")}
              alt="logo"
            />
          </a>
        </div>
        <ul className="nav">
          <li
            className={
              this.isPathActive("/docs/api/guide")
                ? "my-nav-item active"
                : "my-nav-item"
            }
          >
            <div
              className={
                this.state.reportsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("apiGuide")}
              data-toggle="collapse"
            >
              <span
                style={
                  this.isPathActive("/docs/api/guide")
                    ? textColorActive
                    : textColorInActive
                }
                className="menu-title"
              >
                <Trans>Api Refrence Guide</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.apiGuide}>
              <div>
                <ul
                  style={{ paddingLeft: "16px" }}
                  className="nav flex-column sub-menu"
                >
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/docs/api/guide/introduction")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/guide/introduction"
                    >
                      <Trans style={{ color: "#FFF" }}>Introduction</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/docs/api/guide/basics")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/guide/basics"
                    >
                      <Trans style={{ color: "#FFF" }}>Api Basics</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/docs/api/guide/sandbox-setup")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/guide/sandbox-setup"
                    >
                      <Trans style={{ color: "#FFF" }}>
                        Authentication And Sandbox Setup
                      </Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/docs/api/guide/errors")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/guide/errors"
                    >
                      <Trans style={{ color: "#FFF" }}>Errors</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          {/* customers */}
          <li
            className={
              this.isPathActive("/docs/api/customers")
                ? "my-nav-item active"
                : "my-nav-item"
            }
          >
            <div
              className={
                this.state.reportsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("customers")}
              data-toggle="collapse"
            >
              <span
                style={
                  this.isPathActive("/docs/api/customers")
                    ? textColorActive
                    : textColorInActive
                }
                className="menu-title"
              >
                <Trans>Customers</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.customers}>
              <div>
                <ul
                  style={{ paddingLeft: "16px" }}
                  className="nav flex-column sub-menu"
                >
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/docs/api/customers/customer-entity")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/customers/customer-entity"
                    >
                      <Trans>Customer Entity</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive(
                          "/docs/api/customers/get-all-customers"
                        )
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/customers/get-all-customers"
                    >
                      <Trans>Get All Customers</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          {/* agents */}
          <li
            className={
              this.isPathActive("/docs/api/agents")
                ? "my-nav-item active"
                : "my-nav-item"
            }
          >
            <div
              className={
                this.state.reportsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("agents")}
              data-toggle="collapse"
            >
              <span
                style={
                  this.isPathActive("/docs/api/agents")
                    ? textColorActive
                    : textColorInActive
                }
                className="menu-title"
              >
                <Trans>Agents</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.agents}>
              <div>
                <ul
                  style={{ paddingLeft: "16px" }}
                  className="nav flex-column sub-menu"
                >
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/docs/api/agents/agent-entity")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/agents/agent-entity"
                    >
                      <Trans>Agent Entity</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/docs/api/agents/get-all-agents")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/agents/get-all-agents"
                    >
                      <Trans>Get All Agents</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          {/* beneficiary */}
          <li
            className={
              this.isPathActive("/docs/api/beneficiary")
                ? "my-nav-item active"
                : "my-nav-item"
            }
          >
            <div
              className={
                this.state.reportsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("beneficiary")}
              data-toggle="collapse"
            >
              <span
                style={
                  this.isPathActive("/docs/api/beneficiary")
                    ? textColorActive
                    : textColorInActive
                }
                className="menu-title"
              >
                <Trans>Beneficiary</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.beneficiary}>
              <div>
                <ul
                  style={{ paddingLeft: "16px" }}
                  className="nav flex-column sub-menu"
                >
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive(
                          "/docs/api/beneficiary/beneficiary-entity"
                        )
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/beneficiary/beneficiary-entity"
                    >
                      <Trans>beneficiary Entity</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive(
                          "/docs/api/beneficiary/get-all-beneficiary"
                        )
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/beneficiary/get-all-beneficiary"
                    >
                      <Trans>Get All beneficiary</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          {/* smart-collect */}
          <li
            className={
              this.isPathActive("/docs/api/smart-collect")
                ? "my-nav-item active"
                : "my-nav-item"
            }
          >
            <div
              className={
                this.state.reportsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("smartCollect")}
              data-toggle="collapse"
            >
              <span
                style={
                  this.isPathActive("/docs/api/smart-collect")
                    ? textColorActive
                    : textColorInActive
                }
                className="menu-title"
              >
                <Trans>Smart Collect</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.smartCollect}>
              <div>
                <ul
                  style={{ paddingLeft: "16px" }}
                  className="nav flex-column sub-menu"
                >
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive(
                          "/docs/api/smart-collect/virtual-account-entity"
                        )
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/smart-collect/virtual-account-entity"
                    >
                      <Trans>Virtual Account Entity</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive(
                          "/docs/api/smart-collect/get-all-virtual-accounts"
                        )
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/smart-collect/get-all-virtual-accounts"
                    >
                      <Trans>Get All virtual accounts</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive(
                          "/docs/api/smart-collect/virtual-payment-entity"
                        )
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/smart-collect/virtual-payment-entity"
                    >
                      <Trans>Virtual Payment Entity</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive(
                          "/docs/api/smart-collect/get-all-virtual-payments"
                        )
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/smart-collect/get-all-virtual-payments"
                    >
                      <Trans>Get All virtual payments</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          {/* topup */}
          <li
            className={
              this.isPathActive("/docs/api/topup")
                ? "my-nav-item active"
                : "my-nav-item"
            }
          >
            <div
              className={
                this.state.reportsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("topup")}
              data-toggle="collapse"
            >
              <span
                style={
                  this.isPathActive("/docs/api/topup")
                    ? textColorActive
                    : textColorInActive
                }
                className="menu-title"
              >
                <Trans>Topup</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.topup}>
              <div>
                <ul
                  style={{ paddingLeft: "16px" }}
                  className="nav flex-column sub-menu"
                >
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/docs/api/topup/topup-entity")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/topup/topup-entity"
                    >
                      <Trans>Topup Entity</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive(
                          "/docs/api/topup/create-topup-request"
                        )
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/topup/create-topup-request"
                    >
                      <Trans>Create Topup Request</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          {/* webhooks */}
          <li
            className={
              this.isPathActive("/docs/api/webhook")
                ? "my-nav-item active"
                : "my-nav-item"
            }
          >
            <div
              className={
                this.state.reportsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("webhook")}
              data-toggle="collapse"
            >
              <span
                style={
                  this.isPathActive("/docs/api/webhook")
                    ? textColorActive
                    : textColorInActive
                }
                className="menu-title"
              >
                <Trans>Webhook</Trans>
              </span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.webhook}>
              <div>
                <ul
                  style={{ paddingLeft: "16px" }}
                  className="nav flex-column sub-menu"
                >
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/docs/api/webhook/webhook-entity")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/webhook/webhook-entity"
                    >
                      <Trans>Webhook Entity</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/docs/api/webhook/get-all-webhooks")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/webhook/get-all-webhooks"
                    >
                      <Trans>Get All Webhooks</Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/docs/api/webhook/create-webhook")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/docs/api/webhook/create-webhook"
                    >
                      <Trans>Create Webhhok</Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    console.log(path);
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(DocumentationSidebar);
