import React from "react";
import CodeEditor from "./CodeEditor";
import { Tabs, Tab } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import "./ApiViewer.css";
const ApiResponseViewer = ({ response }) => {
  return (
    <div style={{ overflow: "scroll", padding: "0px" }}>
      <Tabs defaultActiveKey="Webhooks" id="uncontrolled-tab-example">
        <Tab eventKey="Response" title="Response" className="test-tab">
          <CodeEditor code={response} lang={"json"} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default ApiResponseViewer;
