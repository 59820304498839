import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import ManageTariffPlanCommissionViewTable from "./ManageTariffPlanCommissionViewTable";
const { SearchBar } = Search;

const whiteStyle = { background: "#FFF" };
const greyStyle = { background: "#e7eaf6" };

const ManageTariffPlanCommission = () => {
  const [recordLoading, setRecordLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [reports, setReports] = useState([]);

  return (
    <div>
      <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo-mini" to="/">
          <img src={require("../../assets/images/logo-mini.svg")} alt="logo" />
        </Link>
      </div>
      {/* <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
        <AgentSideBar details={details} />
      </div> */}

      <div className="col-md-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <Tabs defaultActiveKey="view" id="uncontrolled-tab-example">
              <Tab eventKey="create" title="create" className="test-tab">
                <div>
                  {/* <ManageTariffPlanCommissionViewTable
                    setDetails={(details) => {
                      setDetails({ ...details, sidebarType: "agents" });
                      document
                        .querySelector(".content-wrapper")
                        .classList.toggle("sidebar-icon-only");
                    }}
                  /> */}
                </div>
              </Tab>

              <Tab eventKey="view" title="view" className="test-tab">
                <div>
                  <ManageTariffPlanCommissionViewTable
                    setDetails={(details) => {
                      setDetails({ ...details, sidebarType: "agents" });
                      document
                        .querySelector(".content-wrapper")
                        .classList.toggle("sidebar-icon-only");
                    }}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};
const EmptyArrayHandler = () => {
  return (
    <div className="row">
      <div className="col-12">
        <span style={{ fontSize: "1.4rem" }}>No records Found</span>
      </div>
    </div>
  );
};
export default ManageTariffPlanCommission;
