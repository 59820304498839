import React from 'react'

const GetAllAgentApiDoc = () => {
    return (
        <div>
            Get All agents
        </div>
    )
}

export default GetAllAgentApiDoc
