import React from "react";
import ApiResponseViewer from "../ApiResponseViewer";
const res = `{
    "validateId": 40,
    "customerCode": "454647",
    "beneAccountNo": "45464700001001",
    "beneAccountIfsc": "YESB0CMSNOC",
    "beneFullName": "rajneesh1",
    "transferType": "NEFT",
    "transferUniqueNo": "BKIDN17076467912",
    "transferTimestamp": "2022-02-15T16:44:43",
    "transferCcy": "INR",
    "transferAmt": 210.10,
    "createdBy": "Neeraj@example.com",
    "createdOn": "2022-03-13T18:36:12",
    "userId": 2
}`.trim();
const VirtualPaymentEntity = () => {
  return (
    <div style={{ display: "flex", margin: "6px" }}>
      <div style={{ flex: "1" }}>
        <h3 style={{ margin: "16px" }}>Virtual Payment Entity</h3>
        <div></div>
      </div>
      <div style={{ flex: "1" }}>
        <ApiResponseViewer response={res} />
      </div>
    </div>
  );
};

const Row = ({ first, second }) => {
  return (
    <div
      style={{
        display: "flex",
        border: "1px solid #DDDDDD",
        borderRadius: "4px",
        padding: "6px",
      }}
    >
      <div style={{ flex: "1" }}>{first}</div>
      <div style={{ flex: "2" }}>{second}</div>
    </div>
  );
};

export default VirtualPaymentEntity;
