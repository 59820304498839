import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { DataTables } from "../tables/DataTables";
import { BasicElements } from "../form-elements/BasicElements";
import TransactionTable from "./TransactionsTable";

export class transaction extends Component {
  render() {
    return (
      <div className="col-md-12 grid-margin stretch-card">
        <div style={{ borderRadius: "22px" }} className="card m-4 p-0">
          <h2
            style={{
              margin: "0",
              background: "#3676D8",
              padding: "12px",
              borderTopLeftRadius: "22px",
              color: "#FFFFFF",
              borderTopRightRadius: "22px",
              fontWeight: "bolder",
            }}
          >
            Transactions
          </h2>
          <div className="card-body">
            <Tabs defaultActiveKey="Payments" id="uncontrolled-tab-example">
              <Tab eventKey="Payments" title="Payments" className="test-tab">
                <TransactionTable
                  setDetails={(details) => {
                    // setDetails({ ...details, sidebarType: "payments" });
                    // document
                    //   .querySelector(".content-wrapper")
                    //   .classList.toggle("sidebar-icon-only");
                  }}
                />
              </Tab>

              <Tab eventKey="Refunds" title="Refunds">
              </Tab>

              <Tab eventKey="Batch Refunds" title="Batch Refunds">
              </Tab>

              <Tab eventKey="Orders" title="Orders">
              </Tab>

              <Tab eventKey="Disputes" title="Disputes">
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    );
  }
}

export default transaction;
