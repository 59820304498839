import React from "react";
import ApiResponseViewer from "../ApiResponseViewer";
const res = `{
    "beneficiaryId": 15,
    "lname": "",
    "mname": "",
    "name": "Rajneesh",
    "customerMob": 8860001085,
    "customerName": "0",
    "benefMob": 8860001085,
    "partnerId": 1605,
    "agentId": 101,
    "agentName": null,
    "channel": 1,
    "benefAccNo": "919891167366",
    "title": "Ms",
    "benefIfscCode": "PYTM0123456",
    "address1": "Noida",
    "pincode": "201309",
    "city": "Noida",
    "country": "IN",
    "createdBy": 2,
    "modifyBy": null,
    "createdOn": "2022-04-04T17:41:38",
    "modifyOn": null
}`.trim();
const BeneficiaryEntity = () => {
  return (
    <div style={{ display: "flex", margin: "6px" }}>
      <div style={{ flex: "1" }}>
        <h3 style={{ margin: "16px" }}>Beneficiary Entity</h3>
        <div></div>
      </div>
      <div style={{ flex: "1" }}>
        <ApiResponseViewer response={res} />
      </div>
    </div>
  );
};

const Row = ({ first, second }) => {
  return (
    <div
      style={{
        display: "flex",
        border: "1px solid #DDDDDD",
        borderRadius: "4px",
        padding: "6px",
      }}
    >
      <div style={{ flex: "1" }}>{first}</div>
      <div style={{ flex: "2" }}>{second}</div>
    </div>
  );
};

export default BeneficiaryEntity;
