import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../Context/GlobalState";
import { Doughnut } from "react-chartjs-2";
import { TabsPage } from "../basic-ui/TabsPage";
import { ChartJs } from "../charts/ChartJs";

const mapData = {
  BZ: 75.0,
  US: 56.25,
  AU: 15.45,
  GB: 25.0,
  RO: 10.25,
  GE: 33.25,
};

export const Dashboard = () => {
  const { token, myAccountInfo } = useContext(GlobalContext);
  const [dashboardInfo, setDashboardInfo] = useState({});
  console.log(myAccountInfo);
  // get my account details
  const getDashboardInfo = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const userId = sessionStorage.getItem("userId");
    // console.log("called user id", userId);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Cookie",
      ".AspNetCore.Identity.Application=CfDJ8Knu5GvxZz1PqShq3i6d-iza8XwzTtDsnycoa_JEX2ahOG_6MqIJje0CyELar_eYNiyc8DVdR7WY3yvlwQ9s5qK-G9UoNP6RdZTPbLL0E4Yy__ZCqoFSpUJsPMTzYhGoRbCBLEH3HiICuxNtXPw9IpsW5XRdNUA5lHOG3gBLa-TYZZUXKvhWs78Q25He_Q_MZj04E463t-zUOMNPH_2V9-HWesyARkYFQM9VWEp9jX0s96Zs-yKSE6RKVeiQVbD3QZbMk0tL6DKEREfo0FCVYaOMIGMvKX0GFGsPCXD7L63RcOqMuUCAUU2g1MenEVAWVqlP98WGqkSBtzkDMllZH809ERbz8nKyN9PlkAsXpj2KtJ2OnqWQ2_qr9UH-eFMBMtmRZ4Rcz3qP_Njkx4uwF9MOPeX9EGCoEqOcxTjp9jdC2GWIBON2t0K_Rz7tGFMXe8qGFpeOIZ9DKIZhcVeXiBOb70OBWIcsXQdPr-3rRRTePDDSieVuXUSWR2GnjosxJJqEvCga-MbD59KKJEOJ8cYr2E6dImBGcQuix6g8YCT_eijrXPKNEhZQttEzxYoe-0s0OypnpXEeuOnx9uKta06fT31SqLNomWInObIItLRF4mqH5ZNmnnKxHCatj8r4o7EUKlPTq9fH7D3F7w_Z6KENoOfTC2kz1q5VE7GHM78z"
    );

    var raw = JSON.stringify({
      userId: userId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        "https://apitranxt.paynnow.com/api/Profile/GetDashboardInfo",
        requestOptions
      );
      const data = await res.json();
      if (data.data) {
        setDashboardInfo(data.data);
      }
    } catch (error) {}
  };
  const transactionHistoryData = {
    labels: ["Paypal", "Stripe", "Cash"],
    datasets: [
      {
        data: [55, 25, 20],
        backgroundColor: ["#111111", "#00d25b", "#ffab00"],
      },
    ],
  };

  const transactionHistoryOptions = {
    responsive: true,
    maintainAspectRatio: true,
    segmentShowStroke: false,
    cutoutPercentage: 70,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
    },
  };

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  console.log(sessionStorage.getItem("accessToken"));
  console.log(token);
  useEffect(() => {
    getDashboardInfo();
  }, []);
  return (
    <div className="bg-white-theme">
      <div className="row">
        <div className="col">
          <h3>Transaction Details</h3>
        </div>
      </div>
      <div className="row pt-3">
        <DashboardCard
          label={`Transactions`}
          percentage={`+3.5%`}
          desc={`Since last month`}
          number={dashboardInfo.transactions}
        />
        <DashboardCard
          label={`Settelments`}
          percentage={`+3.5%`}
          desc={`Since last month`}
          number={dashboardInfo.settlement}
        />
        <DashboardCard
          label={`Refunds`}
          percentage={`+3.5%`}
          desc={`Since last month`}
          number={dashboardInfo.refunds}
        />
        <DashboardCard
          label={`Pendings`}
          percentage={`+3.5%`}
          desc={`Since last month`}
          number={dashboardInfo.pending}
        />
      </div>

      <div className="row">
        <div className="col">
          <h3>Bussiness Details</h3>
        </div>
      </div>

      <div className="row">
        <DashboardCard
          label={"DMT"}
          percentage={"+3.5%"}
          desc={"11.38% Since last month"}
          number={dashboardInfo.dmT_business}
        />
        <DashboardCard
          label={"AEPS"}
          percentage={"+8.3%"}
          desc={"9.61% Since last month"}
          number={dashboardInfo.aepS_bbusiness}
        />
        <DashboardCard
          label={"BBPS"}
          percentage={"+8.3%"}
          desc={"9.61% Since last month"}
          number={dashboardInfo.bbpS_business}
        />
        <DashboardCard
          label={"Recharge"}
          percentage={"+8.3%"}
          desc={"9.61% Since last month"}
          number={dashboardInfo.bbpS_offline}
        />
        <DashboardCard
          label={"Payment Gateway"}
          percentage={"+3.5%"}
          desc={"11.38% Since last month"}
          number={dashboardInfo.pG_business}
        />
        <DashboardCard
          label={"CMS"}
          percentage={"+8.3%"}
          desc={"9.61% Since last month"}
          number={dashboardInfo.cmS_business}
        />
      </div>

      <div className="row">
        <div className="col">
          <h3>Agent Onboarding</h3>
        </div>
      </div>

      <div className="row">
        <DashboardCard2
          label={"agent register"}
          percentage={"+3.5%"}
          desc={"11.38% Since last month"}
          number={dashboardInfo.agent_register}
        />
        <DashboardCard2
          label={"agent doc approved"}
          percentage={"+8.3%"}
          desc={"9.61% Since last month"}
          number={dashboardInfo.agent_doc_approved}
        />
        <DashboardCard2
          label={"agent active ekyc"}
          percentage={"+8.3%"}
          desc={"9.61% Since last month"}
          number={dashboardInfo.agent_active_ekyc}
        />
        <DashboardCard2
          label={"agent AEPS ekyc"}
          percentage={"+8.3%"}
          desc={"9.61% Since last month"}
          number={dashboardInfo.agent_AEPS_ekyc}
        />
        <DashboardCard2
          label={"agent active"}
          percentage={"+8.3%"}
          desc={"9.61% Since last month"}
          number={dashboardInfo.agent_active}
          numberClasses={"text-success"}
          iconClasses={"icon-box-success"}
        />
        <DashboardCard2
          label={"agent pending"}
          percentage={"+8.3%"}
          desc={"9.61% Since last month"}
          number={dashboardInfo.agent_pending}
          numberClasses={"text-warning"}
          iconClasses={"icon-box-warning"}
        />
        <DashboardCard2
          label={"agent rejected"}
          percentage={"+8.3%"}
          desc={"9.61% Since last month"}
          number={dashboardInfo.agent_rejected}
          numberClasses={"text-danger"}
          iconClasses={"icon-box-danger"}
        />
      </div>

      <div className="row">
        <div className="col">
          <h3>Commission Details</h3>
        </div>
      </div>

      <div className="row">
        <DashboardCard
          label={"DMT commission"}
          number={dashboardInfo.commission_DMT}
        />
        <DashboardCard
          label={"AEPS commission"}
          number={dashboardInfo.commission_AEPS}
        />
        <DashboardCard
          label={"BBPS commission"}
          number={dashboardInfo.commission_BBPS}
        />
        <DashboardCard
          label={"BBPs offline commission"}
          number={dashboardInfo.commission_BBPs_offline}
        />
        <DashboardCard
          label={"payout commission"}
          number={dashboardInfo.commission_payout}
        />
        <DashboardCard
          label={"PG commission"}
          number={dashboardInfo.commission_PG}
        />
      </div>

      <ChartJs />

      <div className="row">
        <div className="col-md-4 grid-margin stretch-card">
          <div className="card p-0">
            <div className="card-body p-2">
              <h4 className="card-title">Transaction History</h4>
              <div className="aligner-wrapper">
                <Doughnut
                  data={transactionHistoryData}
                  options={transactionHistoryOptions}
                />
                <div className="absolute center-content">
                  <h5 className="font-weight-normal text-whiite text-center mb-2 text-white">
                    1200
                  </h5>
                  <p className="text-small text-muted text-center mb-0">
                    Total
                  </p>
                </div>
              </div>
              <div
                style={{ background: "#F5F5F5" }}
                className="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3"
              >
                <div className="text-md-center text-xl-left">
                  <h6 className="mb-1">Transfer to Paypal</h6>
                  <p className="text-muted mb-0">07 Jan 2019, 09:12AM</p>
                </div>
                <div className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                  <h6 className="font-weight-bold mb-0">$236</h6>
                </div>
              </div>
              <div
                style={{ background: "#F5F5F5" }}
                className="bg-gray-dark d-flex d-md-block d-xl-flex flex-row py-3 px-4 px-md-3 px-xl-4 rounded mt-3"
              >
                <div className="text-md-center text-xl-left">
                  <h6 className="mb-1">Tranfer to Stripe</h6>
                  <p className="text-muted mb-0">07 Jan 2019, 09:12AM</p>
                </div>
                <div className="align-self-center flex-grow text-right text-md-center text-xl-right py-md-2 py-xl-0">
                  <h6 className="font-weight-bold mb-0">$593</h6>
                </div>
              </div>
            </div>
          </div>
        </div>

        <TabsPage />
      </div>

      <div className="row ">
        <div className="col-12 grid-margin">
          <div className="card p-0">
            <div className="card-body p-2">
              <h4 className="card-title">Order Status</h4>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>
                        <div className="form-check form-check-muted m-0">
                          <label className="form-check-label">
                            <input
                              type="checkbox"
                              className="form-check-input"
                            />
                            <i className="input-helper"></i>
                          </label>
                        </div>
                      </th>
                      <th> Client Name </th>
                      <th> Order No </th>
                      <th> Product Cost </th>
                      <th> Project </th>
                      <th> Payment Mode </th>
                      <th> Start Date </th>
                      <th> Payment Status </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="form-check form-check-muted m-0">
                          <label className="form-check-label">
                            <input
                              type="checkbox"
                              className="form-check-input"
                            />
                            <i className="input-helper"></i>
                          </label>
                        </div>
                      </td>
                      <td>
                        <img
                          src={require("../../assets/images/faces/face1.jpg")}
                          alt="face"
                        />
                        <span className="pl-2">Henry Klein</span>
                      </td>
                      <td> 02312 </td>
                      <td> $14,500 </td>
                      <td> Dashboard </td>
                      <td> Credit card </td>
                      <td> 04 Dec 2019 </td>
                      <td>
                        <div className="badge badge-outline-success">
                          Approved
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check form-check-muted m-0">
                          <label className="form-check-label">
                            <input
                              type="checkbox"
                              className="form-check-input"
                            />
                            <i className="input-helper"></i>
                          </label>
                        </div>
                      </td>
                      <td>
                        <img
                          src={require("../../assets/images/faces/face2.jpg")}
                          alt="face"
                        />
                        <span className="pl-2">Estella Bryan</span>
                      </td>
                      <td> 02312 </td>
                      <td> $14,500 </td>
                      <td> Website </td>
                      <td> Cash on delivered </td>
                      <td> 04 Dec 2019 </td>
                      <td>
                        <div className="badge badge-outline-warning">
                          Pending
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check form-check-muted m-0">
                          <label className="form-check-label">
                            <input
                              type="checkbox"
                              className="form-check-input"
                            />
                            <i className="input-helper"></i>
                          </label>
                        </div>
                      </td>
                      <td>
                        <img
                          src={require("../../assets/images/faces/face5.jpg")}
                          alt="face"
                        />
                        <span className="pl-2">Lucy Abbott</span>
                      </td>
                      <td> 02312 </td>
                      <td> $14,500 </td>
                      <td> App design </td>
                      <td> Credit card </td>
                      <td> 04 Dec 2019 </td>
                      <td>
                        <div className="badge badge-outline-danger">
                          Rejected
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check form-check-muted m-0">
                          <label className="form-check-label">
                            <input
                              type="checkbox"
                              className="form-check-input"
                            />
                            <i className="input-helper"></i>
                          </label>
                        </div>
                      </td>
                      <td>
                        <img
                          src={require("../../assets/images/faces/face3.jpg")}
                          alt="face"
                        />
                        <span className="pl-2">Peter Gill</span>
                      </td>
                      <td> 02312 </td>
                      <td> $14,500 </td>
                      <td> Development </td>
                      <td> Online Payment </td>
                      <td> 04 Dec 2019 </td>
                      <td>
                        <div className="badge badge-outline-success">
                          Approved
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="form-check form-check-muted m-0">
                          <label className="form-check-label">
                            <input
                              type="checkbox"
                              className="form-check-input"
                            />
                            <i className="input-helper"></i>
                          </label>
                        </div>
                      </td>
                      <td>
                        <img
                          src={require("../../assets/images/faces/face4.jpg")}
                          alt="face"
                        />
                        <span className="pl-2">Sallie Reyes</span>
                      </td>
                      <td> 02312 </td>
                      <td> $14,500 </td>
                      <td> Website </td>
                      <td> Credit card </td>
                      <td> 04 Dec 2019 </td>
                      <td>
                        <div className="badge badge-outline-success">
                          Approved
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DashboardCard = ({ label, percentage, desc, number }) => {
  return (
    <div className="col-xl-3 col-md-4 col-sm-6 grid-margin stretch-card">
      <div className="card p-0">
        <div className="card-body p-2">
          <div className="row">
            <div className="col-9">
              <div className="d-flex align-items-center align-self-start">
                <h4 className="mb-0">{label}</h4>
                {percentage && (
                  <p className="text-success ml-2 mb-0 font-weight-medium">
                    {percentage}
                  </p>
                )}
              </div>
            </div>
            <div className="col-3">
              <div className="icon icon-box-success">
                <span className="mdi mdi-arrow-top-right icon-item"></span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 py-0 my-0">
              <div className="d-flex align-items-center align-self-start">
                {number && (
                  <h4 className="font-weight-normal py-0 my-0">{number}</h4>
                )}
              </div>
            </div>
          </div>
          {desc && (
            <div className="row">
              <div className="col-12 py-0 my-0">
                <div className="d-flex align-items-center align-self-start">
                  <h6 className="text-muted font-weight-normal py-0 my-0">
                    {desc}
                  </h6>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const DashboardCard2 = ({
  label,
  percentage,
  desc,
  number,
  numberClasses,
  iconClasses,
}) => {
  return (
    <div className="col-xl-3 col-md-4 col-sm-6 grid-margin stretch-card">
      <div className="card p-0">
        <div className="card-body p-2">
          <div className="row">
            <div className="col-9">
              <div className="d-flex align-items-center align-self-start">
                <h4 className="mb-0">{label}</h4>
                <p className={`ml-2 mb-0 font-weight-medium ${numberClasses}`}>
                  {percentage}
                </p>
              </div>
            </div>
            <div className="col-3">
              <div className={`icon ${iconClasses}`}>
                <span className="mdi mdi-arrow-top-right icon-item"></span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 py-0 my-0">
              <div className="d-flex align-items-center align-self-start">
                {number && (
                  <h4
                    className={`font-weight-normal py-0 my-0 ${numberClasses}`}
                  >
                    {number}
                  </h4>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 py-0 my-0">
              <div className="d-flex align-items-center align-self-start">
                <h6 className="text-muted font-weight-normal py-0 my-0">
                  {desc}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
