import { writeFile, utils } from "xlsx";
import crypto from "crypto";

export function ExportToExcel(fileName, data) {
  let wb = utils.book_new();
  let ws = utils.json_to_sheet(data);
  utils.book_append_sheet(wb, ws, "sheet");
  writeFile(wb, `${fileName}.xlsx`);
}

export const createHash = (data) => {
  return crypto.createHash("md5").update(data).digest("hex");
};

export const uniqueID = () => {
  const date = new Date();
  return `${date.getDate() > 9 ? date.getDate() : "0" + date.getDate()}${
    date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
  }${date.getFullYear()}${
    date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
  }${date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()}${
    date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds()
  }`;
};

export const excelMapping = (data, columns) => {
  const arr = [];
  data.forEach((record) => {
    let obj = {};
    columns.forEach((key) => {
      obj[key.text] = record[key.dataField];
    });
    arr.push(obj);
  });
  return arr;
};
